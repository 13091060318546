import { geometries } from "variables/objects"
import { fonts, models, textures } from "variables/objects"
import { CreateBox, CreateCylinder, CreatePlane, CreateText } from "./draw"
import { InitEnv } from "./init"
import { LoadFont, LoadGltf, LoadTexture } from "./loaders"

export function LoadData() {
    let dataRequests = []

    return dataRequests
}

export function LoadMaterials(location) {
    let locationMaterials = []
    for (let [key, value] of Object.entries(models[location])) {
        locationMaterials.push(
            new Promise(function (SetModel, ThrowException) {
                LoadGltf(SetModel, ThrowException, value, key)
            })
        )
    }
    for (let [key, value] of Object.entries(textures[location])) {
        locationMaterials.push(
            new Promise(function (SetTexture, ThrowException) {
                LoadTexture(SetTexture, ThrowException, value.path, key, value.repeatX, value.repeatY)
            })
        )
    }
    return locationMaterials
}

export function DrawObjects(location, textures) {
    let locationObjects = []
    // loop and draw objects
    for (let [key, value] of Object.entries(geometries[location])) {
        if (value.material === null) {
            let textureKey = key + "_" + value.geometry
            value.material = textures.find(t => t.value.key == textureKey).value.obj
        }
        if (value.geometry === "text") {
            locationObjects.push(
                new Promise(function (SetObject, ThrowException) {
                    CreateText(SetObject, ThrowException, key, value)
                })
            )
        }
        if (value.geometry === "plane") {
            locationObjects.push(
                new Promise(function (SetObject, ThrowException) {
                    CreatePlane(SetObject, ThrowException, key, value)
                })
            )
        }
        if (value.geometry === "cylinder") {
            locationObjects.push(
                new Promise(function (SetObject, ThrowException) {
                    CreateCylinder(SetObject, ThrowException, key, value)
                })
            )
        }
        if (value.geometry === "box") {
            locationObjects.push(
                new Promise(function (SetObject, ThrowException) {
                    CreateBox(SetObject, ThrowException, key, value)
                })
            )
        }
    }
    return locationObjects
}

export function LoadEnv(location) {
    return (
        new Promise((SetEnv, ThrowException) => {
            InitEnv(SetEnv, ThrowException, location)
        })
    )
}