const apiKey = 's6Xb-Tqbd-xD5m-uluA'
var forge = require('node-forge')

export function generateHash(signatureString)
{
    var hmac = forge.hmac.create()
    hmac.start('sha256', apiKey)
    hmac.update(signatureString)
    var hashText = hmac.digest().toHex()
    return hashText
}