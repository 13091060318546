import gallery from "../assets/models/gallery.gltf"
import bench from "../assets/models/bench.gltf"
import lights from "../assets/models/lights.gltf"
import wall from "../assets/img/wall.jpg"
import canvas from "../assets/img/canvas.jpg"
import door from "../assets/art/wooddoor.png"
import title from "../assets/art/title.jpg"

import logo1 from "../assets/art/karuna.jpg"
import logo2 from "../assets/art/lion.png"
import logo3 from "../assets/art/DWS.png"
import logo4 from "../assets/art/KATSN.jpg"

import art115 from "../assets/art/D/9.jpg"
import art116 from "../assets/art/KL/30.jpg"

// import art3 from "../assets/art/32.jpg"

import art117 from "../assets/art/LJR/13.jpg"
import art118 from "../assets/art/34.jpg"
import art119 from "../assets/art/LJR/14.jpg"
import art120 from "../assets/art/NC/12.jpg"
import art121 from "../assets/art/S/10.jpg"
import art122 from "../assets/art/33.jpg"
import art123 from "../assets/art/4.jpg"
import art124 from "../assets/art/8.jpg"
import art125 from "../assets/art/13.jpg"
import art126 from "../assets/art/19.jpg"
import art127 from "../assets/art/25.jpg"

// import art15 from "../assets/art/35.jpg"

import art128 from "../assets/art/29.jpg"
import art129 from "../assets/art/32.jpg"
import art130 from "../assets/art/31.jpg"
import art131 from "../assets/art/35.jpg"
import art132 from "../assets/art/BAM/10.jpg"

import { MeshPhongMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { imgContent, titleContent } from "helpers/modal"
import { getArtDetails, getArtName } from "./art7"
const fontLoader = new FontLoader()
export const playerProps = {
    mass: 50.0,
    height: 75
}
export const cameraProps = {
    lobby: {
        position : {
            x: 1700,
            y: playerProps.height,
            z: -200
        },
        rotate: {
            x: 0,
            y: 0.8 * Math.PI,
            z: 0
        }
    }
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	}
}

export const models = {
    lobby: {
        structure: gallery,
        bench1: bench,
        bench2: bench,
        bench3: bench,
        lights1: lights,
        lights2: lights,
        lights3: lights,
        lights4: lights,
        lights5: lights,
        lights6: lights,
        lights15: lights,
        lights16: lights,
        lights17: lights,
        lights18: lights,
        lights19: lights,
        lights20: lights,
    }
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    lobby: {
        block1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block3_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        canvas115_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas116_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        // canvas3_box: {
        //     path: canvas,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        canvas117_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas118_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas119_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas120_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas121_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas122_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas123_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas124_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas125_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas126_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas127_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        // canvas15_box: {
        //     path: canvas,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        canvas128_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas129_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas130_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas131_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas132_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        art115_plane: {
            path: art115,
            repeatX: 1,
            repeatY: 1
        },
        art116_plane: {
            path: art116,
            repeatX: 1,
            repeatY: 1
        },
        // art3_plane: {
        //     path: art3,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        art117_plane: {
            path: art117,
            repeatX: 1,
            repeatY: 1
        },
        art118_plane: {
            path: art118,
            repeatX: 1,
            repeatY: 1
        },
		art119_plane: {
            path: art119,
            repeatX: 1,
            repeatY: 1
        },
		art120_plane: {
            path: art120,
            repeatX: 1,
            repeatY: 1
        },
		art121_plane: {
            path: art121,
            repeatX: 1,
            repeatY: 1
        },
		art122_plane: {
            path: art122,
            repeatX: 1,
            repeatY: 1
        },
		art123_plane: {
            path: art123,
            repeatX: 1,
            repeatY: 1
        },
		art124_plane: {
            path: art124,
            repeatX: 1,
            repeatY: 1
        },
		art125_plane: {
            path: art125,
            repeatX: 1,
            repeatY: 1
        },
        art126_plane: {
            path: art126,
            repeatX: 1,
            repeatY: 1
        },
		art127_plane: {
            path: art127,
            repeatX: 1,
            repeatY: 1
        },
		// art15_plane: {
        //     path: art15,
        //     repeatX: 1,
        //     repeatY: 1
        // },
		art128_plane: {
            path: art128,
            repeatX: 1,
            repeatY: 1
        },
		art129_plane: {
            path: art129,
            repeatX: 1,
            repeatY: 1
        },
		art130_plane: {
            path: art130,
            repeatX: 1,
            repeatY: 1
        },
		art131_plane: {
            path: art131,
            repeatX: 1,
            repeatY: 1
        },
		art132_plane: {
            path: art132,
            repeatX: 1,
            repeatY: 1
        },
        door_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door2_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door3_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door4_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        title_plane: {
            path: title,
            repeatX: 1,
            repeatY: 1
        },
        logo1_plane: {
            path: logo1,
            repeatX: 1,
            repeatY: 1
        },
        logo2_plane: {
            path: logo2,
            repeatX: 1,
            repeatY: 1
        },
        logo3_plane: {
            path: logo3,
            repeatX: 1,
            repeatY: 1
        },
        logo4_plane: {
            path: logo4,
            repeatX: 1,
            repeatY: 1
        },
    }
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value,
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    lobby: {
        block1: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side1: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block2: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side2: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block3: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        canvas115: {
            geometry: "box",
            width: 81.6,
            height: 122,
            depth: 4,
            material: null
        },
        art115: {
            geometry: "plane",
            width: 79.6,
            height: 120,
            material: null
        },
        canvas116: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
        art116: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
        // canvas3: {
        //     geometry: "box",
        //     width: 82,
        //     height: 122,
        //     depth: 4,
        //     material: null
        // },
        // art3: {
        //     geometry: "plane",
        //     width: 80,
        //     height: 120,
        //     material: null
        // },
        canvas117: {
            geometry: "box",
            width: 79.5,
            height: 122,
            depth: 4,
            material: null
        },
        art117: {
            geometry: "plane",
            width: 77.5,
            height: 120,
            material: null
        },
        canvas118: {
            geometry: "box",
            width: 122.0,
            height: 87.0,
            depth: 4,
            material: null
        },
		art118: {
            geometry: "plane",
            width: 120.0,
            height: 85.0,
            material: null
        },
        canvas119: {
            geometry: "box",
            width: 122,
            height: 93.1,
            depth: 4,
            material: null
        },
		art119: {
            geometry: "plane",
            width: 120,
            height: 91.1,
            material: null
        },
		canvas120: {
            geometry: "box",
            width: 81.3,
            height: 122,
            depth: 4,
            material: null
        },
		art120: {
            geometry: "plane",
            width: 79.3,
            height: 120,
            material: null
        },
		canvas121: {
            geometry: "box",
            width: 88.4,
            height: 122,
            depth: 4,
            material: null
        },
		art121: {
            geometry: "plane",
            width: 86.4,
            height: 120,
            material: null
        },
		canvas122: {
            geometry: "box",
            width: 122.0,
            height: 93.1,
            depth: 4,
            material: null
        },
		art122: {
            geometry: "plane",
            width: 120.0,
            height: 91.1,
            material: null
        },
		canvas123: {
            geometry: "box",
            width: 122,
            height: 89.7,
            depth: 4,
            material: null
        },
		art123: {
            geometry: "plane",
            width: 120,
            height: 87.7,
            material: null
        },
		canvas124: {
            geometry: "box",
            width: 122,
            height: 88.5,
            depth: 4,
            material: null
        },
		art124: {
            geometry: "plane",
            width: 120,
            height: 86.5,
            material: null
        },
        canvas125: {
            geometry: "box",
            width: 122,
            height: 92.1,
            depth: 4,
            material: null
        },
		art125: {
            geometry: "plane",
            width: 120,
            height: 90.1,
            material: null
        },
        canvas126: {
            geometry: "box",
            width: 122,
            height: 90.2,
            depth: 4,
            material: null
        },
		art126: {
            geometry: "plane",
            width: 120,
            height: 88.2,
            material: null
        },
		canvas127: {
            geometry: "box",
            width: 122,
            height: 89.1,
            depth: 4,
            material: null
        },
		art127: {
            geometry: "plane",
            width: 120,
            height: 87.1,
            material: null
        },
		// canvas15: {
        //     geometry: "box",
        //     width: 122,
        //     height: 88.5,
        //     depth: 4,
        //     material: null
        // },
		// art15: {
        //     geometry: "plane",
        //     width: 120,
        //     height: 86.5,
        //     material: null
        // },
		canvas128: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art128: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		canvas129: {
            geometry: "box",
            width: 82,
            height: 122,
            depth: 4,
            material: null
        },
		art129: {
            geometry: "plane",
            width: 80,
            height: 120,
            material: null
        },
		canvas130: {
            geometry: "box",
            width: 122.0,
            height: 89.3,
            depth: 4,
            material: null
        },
		art130: {
            geometry: "plane",
            width: 120.0,
            height: 87.3,
            material: null
        },
		canvas131: {
            geometry: "box",
            width: 122,
            height: 88.5,
            depth: 4,
            material: null
        },
		art131: {
            geometry: "plane",
            width: 120,
            height: 86.5,
            material: null
        },
		canvas132: {
            geometry: "box",
            width: 90.8,
            height: 122,
            depth: 4,
            material: null
        },
		art132: {
            geometry: "plane",
            width: 88.8,
            height: 120,
            material: null
        },
        door: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door2: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door3: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door4: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        title: {
            geometry: "plane",
            width: 118,
            height: 78,
            material: null
        },
        logo1: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo2: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo3: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo4: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        }
    }
}

export const lobbyProps = {
    structure: {
        scale: {
            x: 0.25,
            y: 0.2,
            z: 0.6
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    block1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1200,
            y: 75,
            z: 50
        }
    },
    side1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: 480
            // Z: +80
        }
    },
    block2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 600,
            y: 75,
            z: -50
        }
    },
    side2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: -490
        }
    },
    block3_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 0,
            y: 75,
            z: 0
        }
    },
    bench1: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 0,
            z: 0
        }
    },
    bench2: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    bench3: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 300,
            y: 0,
            z: 0
        }
    },
    lights1: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: 350
        }
    },
    lights2: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: 350
        }
    },
    lights3: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: 350
        }
    },
    lights4: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: 350
        }
    },
    lights5: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: 350
        }
    },
    lights6: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: 350
        }
    },
    lights15: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: -250
        }
    },
    lights16: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: -250
        }
    },
    lights17: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: -250
        }
    },
    lights18: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: -250
        }
    },
    lights19: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: -250
        }
    },
    lights20: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: -250
        }
    },
    canvas115_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 210
        }
    },
    art115_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 210
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art115, getArtDetails("115")),
                title: getArtName("115")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas116_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -140
        }
    },
    art116_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -140
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art116, getArtDetails("116")),
                title: getArtName("116")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // canvas3_box: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 1208,
    //         y: 75,
    //         z: -30
    //     }
    // },
    // art3_plane: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 1211,
    //         y: 75,
    //         z: -30
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: imgContent(art3, getArtDetails("3")),
    //             title: getArtName("3")
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    canvas117_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 50
        }
    },
    art117_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 50
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art117, getArtDetails("117")),
                title: getArtName("117")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas118_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 495
        }
    },
    art118_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art118, getArtDetails("118")),
                title: getArtName("118")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas119_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -495
        }
    },
    art119_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art119, getArtDetails("119")),
                title: getArtName("119")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas120_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -495
        }
    },
    art120_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art120, getArtDetails("120")),
                title: getArtName("120")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas121_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 495
        }
    },
    art121_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art121, getArtDetails("121")),
                title: getArtName("121")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas122_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -483
        }
    },
    art122_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art122, getArtDetails("122")),
                title: getArtName("122")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas123_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -483
        }
    },
    art123_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art123, getArtDetails("123")),
                title: getArtName("123")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas124_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 495
        }
    },
    art124_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art124, getArtDetails("124")),
                title: getArtName("124")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas125_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -495
        }
    },
    art125_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art125, getArtDetails("125")),
                title: getArtName("125")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas126_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -495
        }
    },
    art126_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art126, getArtDetails("126")),
                title: getArtName("126")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas127_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: 120
        }
    },
    art127_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: 120
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art127, getArtDetails("127")),
                title: getArtName("127")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // canvas15_box: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 608,
    //         y: 75,
    //         z: 20
    //     }
    // },
    // art15_plane: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 611,
    //         y: 75,
    //         z: 20
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: imgContent(art15, getArtDetails("15")),
    //             title: getArtName("15")
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    canvas128_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -60
        }
    },
    art128_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -60
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art128, getArtDetails("128")),
                title: getArtName("128")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas129_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -220
        }
    },
    art129_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -220
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art129, getArtDetails("129")),
                title: getArtName("129")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas130_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 495
        }
    },
    art130_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art130, getArtDetails("130")),
                title: getArtName("130")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas131_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 473
        }
    },
    art131_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art131, getArtDetails("131")),
                title: getArtName("131")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas132_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 473
        }
    },
    art132_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art132, getArtDetails("132")),
                title: getArtName("132")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryH"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryH"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryF"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryF"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    title_plane: {
        scale: {
            x: 1.2,
            y: 1.2,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 0
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: titleContent(title, getArtDetails("title")),
                title: getArtName("title")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo1_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://karunasarawak.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.lionsclubs.org/en"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.divinewillsociety.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.facebook.com/KATSNKuching/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}
