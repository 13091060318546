import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.2.0";
import "assets/demo/demo.css";

import Lobby from "./views/Venue/Lobby";
import Lobby2 from "./views/Venue/Lobby2";
import Lobby3 from "./views/Venue/Lobby3";
import Lobby4 from "./views/Venue/Lobby4";
import Lobby5 from "./views/Venue/Lobby5";
import Lobby6 from "./views/Venue/Lobby6";
import Lobby7 from "./views/Venue/Lobby7";
import Lobby8 from "./views/Venue/Lobby8";

import ThankYou from "views/ThankYou";
import { FNXCallBack } from "views/FnxCallback";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/thankyou" component={ThankYou} />
      <Route path="/galleryA" component={Lobby} />
      <Route path="/galleryB" component={Lobby2} />
      <Route path="/galleryC" component={Lobby3} />
      <Route path="/galleryD" component={Lobby4} />
      <Route path="/galleryE" component={Lobby5} />
      <Route path="/galleryF" component={Lobby6} />
      <Route path="/galleryG" component={Lobby7} />
      <Route path="/galleryH" component={Lobby8} />
      <Route path="/fnx_callback/:artId" component={FNXCallBack} />
      <Redirect from="*" to="/galleryA" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
