import { generateHash } from "./crypto"
import { addReservation } from "./queries"

const merchantID = 'M100005667'
const curType = 'RM'
const transactionType = '1'
let spayCallbackUrl = 'https://lionsclub.vrsparkvenue.com/spay_callback.php?art='
let fnxCallbackUrl = 'https://lionsclub.vrsparkvenue.com/fnx_callback/'
let fnxFailedUrl = 'https://lionsclub.vrsparkvenue.com/fnx_failure'
// let fnxCallbackUrl = 'localhost:3000/fnx_callback/'
// let fnxFailedUrl = 'localhost:3000/fnx_failure'

export const PrepareSpayOrder = (reservationId, artDetails) => {
    if (parseFloat(artDetails.price)) {
        CreateSpayOrder({
            merOrderNo: reservationId,
            orderAmt: parseFloat(artDetails.price),
            goodsName: artDetails.filename,
            detailURL: 'https://lionsclub.vrsparkvenue.com/thankyou',
            notifyURL: spayCallbackUrl + artDetails.filename
        })
    }
}

export const CreateSpayOrder = ({
    merOrderNo,
    orderAmt,
    goodsName,
    detailURL,
    notifyURL
}) => {
    console.log(notifyURL)
    let signatureString = merchantID + merOrderNo + orderAmt + transactionType
    let signature = generateHash(signatureString)

    let form = document.createElement('form')
    form.target = 'payment'
    form.method = 'POST'
    form.action = 'https://spayolp.sarawak.gov.my/xservice/create-order'
    form.style.display = 'none'
    
    let formParams = {
        merchantId: merchantID,
        merOrderNo,
        orderAmt,
        goodsName,
        curType,
        notifyURL,
        transactionType,
        txn_signature: signature,
        detailURL
    }
    console.log(formParams)

    for (var i in formParams) {
        if (formParams.hasOwnProperty(i)) {
          var input = document.createElement('input');
          input.type = 'hidden';
          input.name = i;
          input.value = formParams[i];
          form.appendChild(input);
        }
    }
    document.body.appendChild(form)
    window.open('', 'payment')
    form.submit()
}

export const PrepareFinexusOrder = (reservationId, artDetails) => {
    window.setVersionNo("06")
    window.setServiceID("FNX")
    window.setMerchantID("000010000012234")
    window.setTxnChannel("WEB")
    window.setLangLocale("en")
    window.setSuccRespURL(fnxCallbackUrl + artDetails.filename)
    window.setUnsuccRespURL(fnxFailedUrl)
    window.setCancelRespURL(fnxFailedUrl)
    window.setQueryRespURL(fnxFailedUrl)
    window.setSHAlgorithmType("SH2")
    window.setSecretKey("B96856FA91749A259F71340E3C6BC3478524320F218587D22071A35DD4E7B4FF")

    window.setPaymentID("U01")
    window.setEcommMerchInd("1")
    window.setMerchRefNo(reservationId)
    window.setCountryCode("MY")
    window.setCurrCode("458")
    // window.setTxnAmt(artDetails.price + ".00")
    window.setTxnAmt("0.01")
    window.setExpTxnAmt("2")
    window.setTokenFlag("N")
    window.setPreAuthFlag("N")
    
    window.location.href = "https://dev.finexusgroup.com:10501/upp/faces/upp/payment.xhtml?" + window.MERCH_UppPaymentRequest()
}

export const CreateFinexusOrder = ({
    merOrderNo,
    orderAmt,
    goodsName,
    detailURL,
    notifyURL
}) => {
    console.log(notifyURL)
    let signatureString = merchantID + merOrderNo + orderAmt + transactionType
    let signature = generateHash(signatureString)

    let form = document.createElement('form')
    form.target = 'payment'
    form.method = 'POST'
    form.action = 'https://spayolp.sains.com.my/xservice/create-order'
    form.style.display = 'none'
    
    let formParams = {
        merchantId: merchantID,
        merOrderNo,
        orderAmt,
        goodsName,
        curType,
        notifyURL,
        transactionType,
        txn_signature: signature,
        detailURL
    }
    console.log(formParams)

    for (var i in formParams) {
        if (formParams.hasOwnProperty(i)) {
          var input = document.createElement('input');
          input.type = 'hidden';
          input.name = i;
          input.value = formParams[i];
          form.appendChild(input);
        }
    }
    document.body.appendChild(form)
    window.open('', 'payment')
    form.submit()
}