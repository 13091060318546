import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"
const firebaseConfig = {
    apiKey: "AIzaSyBo_0_QtRicoYVc4C4vnpY_hXRFGC5ajps",
    authDomain: "virtual-arts.firebaseapp.com",
    databaseURL: "https://virtual-arts-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "virtual-arts",
    storageBucket: "virtual-arts.appspot.com",
    messagingSenderId: "666722220598",
    appId: "1:666722220598:web:2a9e9bc7c6693cb0073697"
}

const app = initializeApp(firebaseConfig)
export default app