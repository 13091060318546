import { Vector3 } from "three"
import { playerProps } from "variables/objects"
import { ControlProps } from "variables/objects"

export default function animate(scene, camera, renderer, prevTime, movement, control, effects, interaction) {
	
	let { enabled, moveForward, moveBackward, moveLeft, moveRight, turnAngle } = movement
    let { velocity, direction } = ControlProps
	requestAnimationFrame(() => animate(scene, camera, renderer, prevTime, movement, control, effects, interaction))
	const time = performance.now()
	const delta = (time - prevTime) / 1000

	effects.forEach(element => {
		if (element.animation == "rotate") {
			element.obj.rotateX(element.value.x)
			element.obj.rotateY(element.value.y)
			element.obj.rotateZ(element.value.z)
		}
		if (element.animation == "position") {
			element.obj.position.x += element.value.x
			element.obj.position.y += element.value.y
			element.obj.position.z += element.value.z
		}
		if (element.animation == "shake") {
			if (element.altered == null) {
				element.altered = {
					x: "increment",
					y: "increment",
					z: "increment"
				}
			}
			if (element.altered.x == "increment") {
				element.obj.position.x += element.value.x
			}
			if (element.altered.y == "increment") {
				element.obj.position.y += element.value.y
			}
			if (element.altered.z == "increment") {
				element.obj.position.z += element.value.z
			}
			if (element.altered.x == "decrement") {
				element.obj.position.x -= element.value.x
			}
			if (element.altered.y == "decrement") {
				element.obj.position.y -= element.value.y
			}
			if (element.altered.z == "decrement") {
				element.obj.position.z -= element.value.z
			}
			console.log(element.obj.position.x < element.min.x)
			if (element.obj.position.x < element.min.x) {
				element.altered.x = "increment"
			}
			console.log(element.obj.position.x > element.max.x)
			if (element.obj.position.x > element.max.x) {
				element.altered.x = "decrement"
			}
			if (element.obj.position.y < element.min.y) {
				element.altered.y = "increment"
			}
			if (element.obj.position.y > element.max.y) {
				element.altered.y = "decrement"
			}
			if (element.obj.position.z < element.min.z) {
				element.altered.z = "increment"
			}
			if (element.obj.position.z > element.max.z) {
				element.altered.z = "decrement"
			}
			console.log(element.obj.position.x)
			console.log(element.min.x)
		}
		if (element.animation == "pump") {
			if (element.obj.scale.x < element.min.x) {
				element.obj.scale.x += element.value
			}
			if (element.obj.scale.x > element.max.x) {
				element.obj.scale.x -= element.value
			}
			if (element.obj.scale.y < element.min.y) {
				element.obj.scale.y += element.value
			}
			if (element.obj.scale.y > element.max.y) {
				element.obj.scale.y -= element.value
			}
			if (element.obj.scale.z < element.min.z) {
				element.obj.scale.z += element.value
			}
			if (element.obj.scale.z > element.max.z) {
				element.obj.scale.z -= element.value
			}
		}
		if (element.animation == "follow" && !moveBackward && !moveForward) {
			let centerPoint = element.obj
			let subject = control.getObject()
			let x0 = centerPoint.position.x
			let z0 = centerPoint.position.z
			let x1 = subject.position.x
			let z1 = subject.position.z
			if (x0 == x1) { // x axis intercept
				if (z0 <= z1) {
					centerPoint.rotateY(-(centerPoint.rotation._y - element.value.rotation.y))
				}
				if (z0 > z1) {
					centerPoint.rotateY(-(centerPoint.rotation._y - (element.value.rotation.y + Math.PI)))
				}
			} else if (z0 == z1) { // z axis intercept
				if (x0 < x1) {
					// + pi x 0.5
					centerPoint.rotateY(-(centerPoint.rotation._y - (element.value.rotation.y + Math.PI * 0.5)))
				}
				if (z0 > z1) {
					// + pi x 1.5
					centerPoint.rotateY(-(centerPoint.rotation._y - (element.value.rotation.y + Math.PI * 1.5)))
				}
			} else {
				let adjacent, opposite, degree, quadrantStart, radian
				// below 90 degrees
				if (centerPoint.position.x < subject.position.x && centerPoint.position.z > subject.position.z) {
					quadrantStart = 0
					adjacent = centerPoint.position.z - subject.position.z
					opposite = subject.position.x - centerPoint.position.x
				}
				// between 90-180 degrees
				if (centerPoint.position.x < subject.position.x && centerPoint.position.z < subject.position.z) {
					quadrantStart = 90
					adjacent = subject.position.x - centerPoint.position.x
					opposite = subject.position.z - centerPoint.position.z
				}
				// between 180-270 degrees
				if (centerPoint.position.x > subject.position.x && centerPoint.position.z < subject.position.z) {
					quadrantStart = 180
					adjacent = subject.position.z - centerPoint.position.z
					opposite = centerPoint.position.x - subject.position.x
				}
				// above 270 degrees
				if (centerPoint.position.x > subject.position.x && centerPoint.position.z > subject.position.z) {
					quadrantStart = 270
					adjacent = centerPoint.position.x - subject.position.x
					opposite = centerPoint.position.z - subject.position.z
				}
				degree = Math.tan(opposite / adjacent)
				radian = (quadrantStart + degree) * Math.PI / 180
				if (centerPoint.altered != radian) {
					centerPoint.rotateY(radian - centerPoint.rotation._y)
					centerPoint.altered = radian
				}
			}
		}
	})
	
	velocity.x -= velocity.x * 10.0 * delta
	velocity.y -= 9.8 * playerProps.mass * delta
	velocity.z -= velocity.z * 10.0 * delta
	let addDist = 0
	let addRotation = 0
	if (window.innerWidth < window.innerHeight) {
		addDist = 4
		addRotation = 0.05
	}
	direction.z = (Number(moveForward) * (0.3 + addDist)) - (Number(moveBackward) * (0.3 + addDist))
	let rotateAngle =  (Number(moveLeft) * (0.01 + addRotation)) - (Number(moveRight) * (0.01 + addRotation))
	if (moveForward || moveBackward) {
		velocity.z -= direction.z * 6000.0 * delta
	}
	if (moveLeft || moveRight) {
		camera.rotateY(rotateAngle)
	}
	if (window.innerWidth < window.innerHeight) {
		camera.rotateY(turnAngle / 180 * Math.PI)
	}
	control.moveForward(-velocity.z * delta)
	control.getObject().position.y += velocity.y * delta
	if (control.getObject().position.y < playerProps.height) {
		control.getObject().position.y = playerProps.height
		velocity.y = 0
	}
	if (window.innerWidth < window.innerHeight) {
		movement.moveForward = false
		movement.moveBackward = false
		movement.moveLeft = false
		movement.moveRight = false
	}
	let modelScale = new Vector3
	control.getObject().getWorldPosition(modelScale)
	// console.log(modelScale)
	prevTime = time
	interaction.update()
	renderer.render(scene, camera)
}