import { Scene, PerspectiveCamera, WebGLRenderer, AmbientLight, HemisphereLight, Color, Vector3, MathUtils } from "three"
import { cameraProps } from "variables/objects"
import { InteractionManager } from "three.interactive"
import { HandleKeyDown, HandleKeyUp, HandleMouseDown, HandleOrient, OnWindowResize } from "./handlers"
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js"
import { MovementProps } from "variables/objects"
import { Sky } from "three/examples/jsm/objects/Sky.js"
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js"

export function InitEnv(SetEnv, ThrowException, location) {
    let scene = new Scene()
    let camera = InitCamera(location)
    let renderer = InitRenderer()
    let interaction = new InteractionManager(
        renderer,
        camera,
        renderer.domElement
    )
    let lightings = InitLighting(location)
    lightings.forEach((element) => {
        scene.add(element)
    })
    window.movement = MovementProps
    let movement = window.movement
    let prevTime = performance.now()
    document.body.appendChild(renderer.domElement)
    window.addEventListener("resize", () => OnWindowResize(camera, renderer), false)
    if (window.innerWidth > window.innerHeight) {
        document.addEventListener("keydown", (event) => HandleKeyDown(event, movement))
        document.addEventListener("keyup", (event) => HandleKeyUp(event, movement))
    }
    else {
        window.addEventListener("deviceorientation", (event) => HandleOrient (event, movement), true)
        document.addEventListener("mousedown", (event) => HandleMouseDown(event, movement))
    }
    let control = new PointerLockControls(camera, renderer.domElement)
    scene.add(control.getObject())
    initSky(scene, renderer, camera)
    SetEnv({
        scene,
        camera,
        renderer,
        interaction,
        prevTime,
        movement,
        control
    })
}

function InitLighting(location) {
    let lightings = []
    if (location == "lobby") {
        let hemiLight = new HemisphereLight(0xf7e6ff, 0xffffff, 0.9)
        hemiLight.position.set( -19, 5, 22 )
        hemiLight.castShadow = true
        lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xaeade0, 0.5)
        lightings.push(ambientLight)
    }

    return lightings
}

function InitCamera(location) {
    let camera = new PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        6000
    )
    let {position, rotate} = cameraProps[location]
    camera.position.x = position.x
    camera.position.y = position.y
    camera.position.z = position.z
    camera.rotateX(rotate.x)
    camera.rotateY(rotate.y)
    camera.rotateZ(rotate.z)
    return camera
}

function InitRenderer() {
    let renderer = new WebGLRenderer({
        antialias: true,
        alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(new Color(0x061D37), 0.8)

    return renderer
}

function initSky(scene, renderer, camera) {

    // Add Sky
    let sky = new Sky()
    sky.scale.setScalar( 450000 )
    scene.add( sky )

    let sun = new Vector3()

    /// GUI

    const effectController = {
        turbidity: 10,
        rayleigh: 3,
        mieCoefficient: 0.005,
        mieDirectionalG: 0.7,
        elevation: 2,
        azimuth: 180,
        exposure: renderer.toneMappingExposure
    }

    function guiChanged(renderer, camera) {

        const uniforms = sky.material.uniforms
        uniforms[ 'turbidity' ].value = effectController.turbidity
        uniforms[ 'rayleigh' ].value = effectController.rayleigh
        uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient
        uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG

        const phi = MathUtils.degToRad( 90 - effectController.elevation )
        const theta = MathUtils.degToRad( effectController.azimuth )

        sun.setFromSphericalCoords( 1, phi, theta )

        uniforms[ 'sunPosition' ].value.copy( sun )

        renderer.toneMappingExposure = effectController.exposure
        renderer.render( scene, camera )

    }

    guiChanged(renderer, camera)
}