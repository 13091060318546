import gallery from "../assets/models/gallery.gltf"
import bench from "../assets/models/bench.gltf"
import lights from "../assets/models/lights.gltf"
import wall from "../assets/img/wall.jpg"
import canvas from "../assets/img/canvas.jpg"
import door from "../assets/art/wooddoor.png"
import title from "../assets/art/title.jpg"

import logo1 from "../assets/art/karuna.jpg"
import logo2 from "../assets/art/lion.png"
import logo3 from "../assets/art/DWS.png"
import logo4 from "../assets/art/KATSN.jpg"

import art96 from "../assets/art/S/7.jpg"
import art97 from "../assets/art/BAM/9.jpg"
import art98 from "../assets/art/26.jpg"
import art99 from "../assets/art/D/8.jpg"
import art100 from "../assets/art/27.jpg"
import art101 from "../assets/art/KL/24.jpg"
import art102 from "../assets/art/LJR/11.jpg"
import art103 from "../assets/art/KL/25.jpg"
import art104 from "../assets/art/28.jpg"
import art105 from "../assets/art/S/8.jpg"
import art106 from "../assets/art/KL/26.jpg"
import art107 from "../assets/art/KL/29.jpg"
import art108 from "../assets/art/KL/27.jpg"
import art109 from "../assets/art/KL/28.jpg"

// import art965 from "../assets/art/29.jpg"

import art110 from "../assets/art/LJR/12.jpg"
import art111 from "../assets/art/NC/10.jpg"
import art112 from "../assets/art/30.jpg"
import art113 from "../assets/art/S/9.jpg"
import art114 from "../assets/art/NC/11.jpg"

import { MeshPhongMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { imgContent, titleContent } from "helpers/modal"
import { getArtDetails, getArtName } from "./art6"
const fontLoader = new FontLoader()
export const playerProps = {
    mass: 50.0,
    height: 75
}
export const cameraProps = {
    lobby: {
        position : {
            x: 1700,
            y: playerProps.height,
            z: -200
        },
        rotate: {
            x: 0,
            y: 0.8 * Math.PI,
            z: 0
        }
    }
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	}
}

export const models = {
    lobby: {
        structure: gallery,
        bench1: bench,
        bench2: bench,
        bench3: bench,
        lights1: lights,
        lights2: lights,
        lights3: lights,
        lights4: lights,
        lights5: lights,
        lights6: lights,
        lights15: lights,
        lights16: lights,
        lights17: lights,
        lights18: lights,
        lights19: lights,
        lights20: lights,
    }
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    lobby: {
        block1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block3_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        canvas1_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas2_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas3_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas4_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas5_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas6_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas7_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas8_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas9_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas10_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas11_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas107_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas108_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas109_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        // canvas15_box: {
        //     path: canvas,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        canvas110_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas111_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas112_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas113_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas114_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        art96_plane: {
            path: art96,
            repeatX: 1,
            repeatY: 1
        },
        art97_plane: {
            path: art97,
            repeatX: 1,
            repeatY: 1
        },
        art98_plane: {
            path: art98,
            repeatX: 1,
            repeatY: 1
        },
        art99_plane: {
            path: art99,
            repeatX: 1,
            repeatY: 1
        },
        art100_plane: {
            path: art100,
            repeatX: 1,
            repeatY: 1
        },
		art101_plane: {
            path: art101,
            repeatX: 1,
            repeatY: 1
        },
		art102_plane: {
            path: art102,
            repeatX: 1,
            repeatY: 1
        },
		art103_plane: {
            path: art103,
            repeatX: 1,
            repeatY: 1
        },
		art104_plane: {
            path: art104,
            repeatX: 1,
            repeatY: 1
        },
		art105_plane: {
            path: art105,
            repeatX: 1,
            repeatY: 1
        },
		art106_plane: {
            path: art106,
            repeatX: 1,
            repeatY: 1
        },
		art107_plane: {
            path: art107,
            repeatX: 1,
            repeatY: 1
        },
        art108_plane: {
            path: art108,
            repeatX: 1,
            repeatY: 1
        },
		art109_plane: {
            path: art109,
            repeatX: 1,
            repeatY: 1
        },
		// art965_plane: {
        //     path: art965,
        //     repeatX: 1,
        //     repeatY: 1
        // },
		art110_plane: {
            path: art110,
            repeatX: 1,
            repeatY: 1
        },
		art111_plane: {
            path: art111,
            repeatX: 1,
            repeatY: 1
        },
		art112_plane: {
            path: art112,
            repeatX: 1,
            repeatY: 1
        },
		art113_plane: {
            path: art113,
            repeatX: 1,
            repeatY: 1
        },
		art114_plane: {
            path: art114,
            repeatX: 1,
            repeatY: 1
        },
        door_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door2_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door3_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door4_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        title_plane: {
            path: title,
            repeatX: 1,
            repeatY: 1
        },
        logo1_plane: {
            path: logo1,
            repeatX: 1,
            repeatY: 1
        },
        logo2_plane: {
            path: logo2,
            repeatX: 1,
            repeatY: 1
        },
        logo3_plane: {
            path: logo3,
            repeatX: 1,
            repeatY: 1
        },
        logo4_plane: {
            path: logo4,
            repeatX: 1,
            repeatY: 1
        },
    }
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value,
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    lobby: {
        block1: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side1: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block2: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side2: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block3: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        canvas1: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
        art96: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas2: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
        art97: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas3: {
            geometry: "box",
            width: 88.3,
            height: 122,
            depth: 4,
            material: null
        },
        art98: {
            geometry: "plane",
            width: 86.3,
            height: 120,
            material: null
        },
        canvas4: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
        art99: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas5: {
            geometry: "box",
            width: 122.0,
            height: 90.8,
            depth: 4,
            material: null
        },
		art100: {
            geometry: "plane",
            width: 120.0,
            height: 88.8,
            material: null
        },
        canvas6: {
            geometry: "box",
            width: 122,
            height: 92,
            depth: 4,
            material: null
        },
		art101: {
            geometry: "plane",
            width: 120,
            height: 90,
            material: null
        },
		canvas7: {
            geometry: "box",
            width: 97.2,
            height: 122,
            depth: 4,
            material: null
        },
		art102: {
            geometry: "plane",
            width: 95.2,
            height: 120,
            material: null
        },
		canvas8: {
            geometry: "box",
            width: 102,
            height: 122,
            depth: 4,
            material: null
        },
		art103: {
            geometry: "plane",
            width: 100,
            height: 120,
            material: null
        },
		canvas9: {
            geometry: "box",
            width: 122.0,
            height: 82.0,
            depth: 4,
            material: null
        },
		art104: {
            geometry: "plane",
            width: 120.0,
            height: 80.0,
            material: null
        },
		canvas10: {
            geometry: "box",
            width: 91.5,
            height: 122,
            depth: 4,
            material: null
        },
		art105: {
            geometry: "plane",
            width: 89.5,
            height: 120,
            material: null
        },
		canvas11: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art106: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas107: {
            geometry: "box",
            width: 122,
            height: 87.7,
            depth: 4,
            material: null
        },
		art107: {
            geometry: "plane",
            width: 120,
            height: 85.7,
            material: null
        },
        canvas108: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art108: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		canvas109: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art109: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
		// canvas15: {
        //     geometry: "box",
        //     width: 122,
        //     height: 82,
        //     depth: 4,
        //     material: null
        // },
		// art965: {
        //     geometry: "plane",
        //     width: 120,
        //     height: 80,
        //     material: null
        // },
		canvas110: {
            geometry: "box",
            width: 99.6,
            height: 122,
            depth: 4,
            material: null
        },
		art110: {
            geometry: "plane",
            width: 97.6,
            height: 120,
            material: null
        },
		canvas111: {
            geometry: "box",
            width: 81.3,
            height: 122,
            depth: 4,
            material: null
        },
		art111: {
            geometry: "plane",
            width: 79.3,
            height: 120,
            material: null
        },
		canvas112: {
            geometry: "box",
            width: 122,
            height: 88.5,
            depth: 4,
            material: null
        },
		art112: {
            geometry: "plane",
            width: 120.0,
            height: 86.5,
            material: null
        },
		canvas113: {
            geometry: "box",
            width: 122,
            height: 88.4,
            depth: 4,
            material: null
        },
		art113: {
            geometry: "plane",
            width: 120,
            height: 86.4,
            material: null
        },
		canvas114: {
            geometry: "box",
            width: 122,
            height: 80.4,
            depth: 4,
            material: null
        },
		art114: {
            geometry: "plane",
            width: 120,
            height: 78.4,
            material: null
        },
        door: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door2: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door3: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door4: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        title: {
            geometry: "plane",
            width: 118,
            height: 78,
            material: null
        },
        logo1: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo2: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo3: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo4: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        }
    }
}

export const lobbyProps = {
    structure: {
        scale: {
            x: 0.25,
            y: 0.2,
            z: 0.6
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    block1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1200,
            y: 75,
            z: 50
        }
    },
    side1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: 480
            // Z: +80
        }
    },
    block2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 600,
            y: 75,
            z: -50
        }
    },
    side2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: -490
        }
    },
    block3_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 0,
            y: 75,
            z: 0
        }
    },
    bench1: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 0,
            z: 0
        }
    },
    bench2: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    bench3: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 300,
            y: 0,
            z: 0
        }
    },
    lights1: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: 350
        }
    },
    lights2: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: 350
        }
    },
    lights3: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: 350
        }
    },
    lights4: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: 350
        }
    },
    lights5: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: 350
        }
    },
    lights6: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: 350
        }
    },
    lights15: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: -250
        }
    },
    lights16: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: -250
        }
    },
    lights17: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: -250
        }
    },
    lights18: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: -250
        }
    },
    lights19: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: -250
        }
    },
    lights20: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: -250
        }
    },
    canvas1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 250
        }
    },
    art96_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 250
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art96, getArtDetails("96")),
                title: getArtName("96")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -160
        }
    },
    art97_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -160
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art97, getArtDetails("97")),
                title: getArtName("97")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas3_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -30
        }
    },
    art98_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -30
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art98, getArtDetails("98")),
                title: getArtName("98")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas4_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 100
        }
    },
    art99_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 100
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art99, getArtDetails("99")),
                title: getArtName("99")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas5_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 495
        }
    },
    art100_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art100, getArtDetails("100")),
                title: getArtName("100")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas6_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -495
        }
    },
    art101_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art101, getArtDetails("101")),
                title: getArtName("101")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas7_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -495
        }
    },
    art102_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art102, getArtDetails("102")),
                title: getArtName("102")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas8_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 495
        }
    },
    art103_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art103, getArtDetails("103")),
                title: getArtName("103")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas9_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -483
        }
    },
    art104_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art104, getArtDetails("104")),
                title: getArtName("104")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas10_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -483
        }
    },
    art105_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art105, getArtDetails("105")),
                title: getArtName("105")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas11_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 495
        }
    },
    art106_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art106, getArtDetails("106")),
                title: getArtName("106")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas107_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -495
        }
    },
    art107_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art107, getArtDetails("107")),
                title: getArtName("107")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas108_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -495
        }
    },
    art108_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art108, getArtDetails("108")),
                title: getArtName("108")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas109_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: 120
        }
    },
    art109_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: 120
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art109, getArtDetails("109")),
                title: getArtName("109")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // canvas15_box: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 608,
    //         y: 75,
    //         z: 20
    //     }
    // },
    // art965_plane: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 611,
    //         y: 75,
    //         z: 20
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: imgContent(art965, getArtDetails("15")),
    //             title: getArtName("15")
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    canvas110_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -60
        }
    },
    art110_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -60
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art110, getArtDetails("110")),
                title: getArtName("110")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas111_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -220
        }
    },
    art111_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -220
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art111, getArtDetails("111")),
                title: getArtName("111")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas112_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 495
        }
    },
    art112_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art112, getArtDetails("112")),
                title: getArtName("112")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas113_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 473
        }
    },
    art113_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art113, getArtDetails("113")),
                title: getArtName("113")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas114_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 473
        }
    },
    art114_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art114, getArtDetails("114")),
                title: getArtName("114")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryG"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryG"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryE"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryE"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    title_plane: {
        scale: {
            x: 1.2,
            y: 1.2,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 0
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: titleContent(title, getArtDetails("title")),
                title: getArtName("title")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo1_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://karunasarawak.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.lionsclubs.org/en"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.divinewillsociety.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.facebook.com/KATSNKuching/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}
