import up from "../assets/img/up.png"
import down from "../assets/img/down.png"
import left from "../assets/img/left.png"
import right from "../assets/img/right.png"
import w from "../assets/img/w.png"
import a from "../assets/img/a.png"
import s from "../assets/img/s.png"
import d from "../assets/img/d.png"
import click from "../assets/img/d.png"
import mleft from "../assets/img/mleft.png"
import mright from "../assets/img/mright.png"
import mfront from "../assets/img/mfront.png"
import mback from "../assets/img/mback.png"
import iosleft from "../assets/img/iosleft.png"
import iosright from "../assets/img/iosright.png"
import iosfront from "../assets/img/iosfront.png"
import iosback from "../assets/img/iosback.png"
import { isIOS } from "react-device-detect"
import { Button, Col, Modal, Row } from 'reactstrap';
import { addReservation, checkReserved, getReservations, updateReservation } from "./queries"
import { child, get, getDatabase, onValue, ref, set } from "firebase/database"
import app from "./firebase"
import { PrepareFinexusOrder, PrepareOrder, PrepareSpayOrder } from "./payment"
import ReactDOMServer from 'react-dom/server'

export function iframeContent(url) {
    return (
        <div>
            <iframe src={url}/>
        </div>
    )
}

export function imgContent(resource, details) {
    return (
        <>
            <Row>
                <Col xs="12" md="6" lg="6">
                    <p style={{
                        textAlign: 'center'
                    }}>
                        <img src={resource} style={{
                            maxHeight: '70vh'
                        }} />
                    </p>
                </Col>
                <Col xs="12" md="6" lg="6">
                    {(
                        details.price == "Not for sale" ? <></> :
                        <>
                            <p>
                                Size: {details.size}
                            </p>
                            <p>
                                Art Media: {details.media}
                            </p>
                            <p>
                                Selling Price: RM {details.price}
                            </p>
                        </>
                    )}
                    <h4 style={{
                        color: "#000"
                    }}>
                        by {details.artist}
                    </h4>
                    <br/>
                    <p dangerouslySetInnerHTML={{__html: details.profile}}>
                    </p>
                </Col>
            </Row>
            {(
                details.price == "Not for sale" ? <></> : 
                <>
                    <p style={{ 
                        textAlign: 'center'
                    }}>
                        <Button
                            id={"btnPurchase" + details.filename}
                            className="btn-round mx-2"
                            color="success"
                            type="button"
                            onClick={() => formModal(details)}
                        >
                            Purchase
                        </Button>
                    </p>
                </>
            )}
        </>
    )
}

export function shippingForm(details) {
    
    document.getElementById("title").innerHTML = 'Shipping Details'

    return (
        <div className="card-body">
            <div className="row justify-content-center">
                <div className="input-group" style={{width:'60%'}} >
                    <input id="name" type="text" className="form-control" placeholder="Full Name *" style={{color: '#000'}}/>
                </div>
                <div className="input-group" style={{width:'60%'}}>                   
                    <input id="email" type="email" placeholder="Email *" className="form-control" style={{color: '#000'}}/>
                </div>
                <div className="input-group" style={{width:'60%'}}>
                    <input id="contact" type="text" placeholder="Phone No. *" className="form-control" style={{color: '#000'}}/>
                </div>
                <div className="input-group" style={{width:'60%'}}>
                    <input id="address" type="textarea" className="form-control" placeholder="Shipping Address *" style={{color: '#000'}}/>
                </div>
            </div>
            <p style={{textAlign: 'center', fontWeight: '600'}}>**Note: Shipping fees is not included. Please ensure your details are correct. KATSN will contact you for shipping arrangements within ONE   week.</p>
            <div className="card-footer"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}>
            <Button 
                className="btn btn-info btn-round btn-lg"
                id={"btnSpay" + details.filename}
            >
                S Pay Global
            </Button>
            {/* <Button 
                className="btn btn-info btn-round btn-lg"
                id={"btnFinexus" + details.filename}
            >
                FinexusPay
            </Button> */}

            </div>
        </div>
     );
   }

export function formModal(details) {
    addReservation(details.filename, (reservationId) => {
        let form = document.getElementById('openModal')
        form.innerHTML = ReactDOMServer.renderToString(shippingForm(details))
        document.getElementById("btnSpay" + details.filename).addEventListener("click", (e) => GetShippingDetails(reservationId, details, 'spay'))
        // document.getElementById("btnFinexus" + details.filename).addEventListener("click", (e) => GetShippingDetails(reservationId, details, 'finexus'))
    })
}

function GetShippingDetails(reservationId, artDetails, paymentType) {
    console.log(typeof window.setSecretKey)
    // use document.getElementById(xxx).value to get input value
    var name = document.getElementById('name').value;
    var email = document.getElementById('email').value;
    var contact = document.getElementById('contact').value;
    var address = document.getElementById('address').value;
    if (name == '' || email == '' || contact == '' || address == '') {
        alert("Please fill in all the details!")
        return
    }
    // put shipping details into json format
    let shippingDetails = {
        name,
        email,
        contact,
        address  
    }
    updateReservation(reservationId, artDetails.filename, shippingDetails)
    if (paymentType == 'spay') {
        PrepareSpayOrder(reservationId, artDetails)
    } else {
        PrepareFinexusOrder(reservationId, artDetails)
    }
    // document.getElementById("closeModal").click()
}

export function titleContent(resource, details) {
    return (
        <>
            <p style={{
                textAlign: 'center'
            }}>
                <img src={resource} style={{
                    maxHeight: '70vh'
                }} />
            </p>
            <p style={{
                textAlign: 'center'
            }}>
            </p>
        </>
    )
}

export function nfsContent(resource, details) {
    return (
        <>
            <p style={{
                textAlign: 'center'
            }}>
                <img src={resource} style={{
                    maxHeight: '70vh'
                }} />
            </p>
            <p style={{
                textAlign: 'center'
            }}>
                by {details.artist}
                <br/>
            </p>
        </>
    )
}

export function videoContent(resource) {
    return (
        <video src={resource} controls />
    )
}

const pcInstructions = (
    <>
        <h2>PC Controls</h2>
        <table className="instructions-table">
            <tr>
                <td>
                <img src={up} />
                or
                <img src={w} />
                </td>
                <td>Move forward</td>
            </tr>
            <tr>
                <td>
                <img src={down} />
                or
                <img src={s} />
                </td>
                <td>Move backward</td>
            </tr>
            <tr>
                <td>
                <img src={left} />
                or
                <img src={a} />
                </td>
                <td>Turn left</td>
            </tr>
            <tr>
                <td>
                <img src={right} />
                or
                <img src={d} />
                </td>
                <td>Turn right</td>
            </tr>
            <tr>
                <td>
                <img src={click} />
                </td>
                <td>Click to interact</td>
            </tr>
        </table>
    </>
)

const mobileInstructions = (
    <>
        <table className="ios-instructions-table">
            <tr>
                <td>
                    <img src={mfront} />
                </td>
                <td>
                    <img src={mback} />
                </td>
                <td>
                    <img src={mleft} />
                </td>
                <td>
                    <img src={mright} />
                </td>
                <td colSpan={2}>Click on items to interact</td>
            </tr>
            <tr>
                <td>Move forward</td>
                <td>Move backward</td>
                <td>Turn left</td>
                <td>Turn right</td>
            </tr>
        </table>
    </>
)

const mobileIosInstructions = (
    <>
        <table className="ios-instructions-table">
            <tr>
                <td>
                    <img src={iosfront} />
                </td>
                <td>
                    <img src={iosback} />
                </td>
                <td>
                    <img src={iosleft} />
                </td>
                <td>
                    <img src={iosright} />
                </td>
                <td colSpan={2}>Click on items to interact</td>
            </tr>
            <tr>
                <td>Move forward</td>
                <td>Move backward</td>
                <td>Turn left</td>
                <td>Turn right</td>
            </tr>
        </table>
    </>
)

export const instructions = () => {
    if (window.innerWidth < window.innerHeight) {
        if (isIOS) {
            return mobileIosInstructions
        }
        else {
            return mobileInstructions
        }
    } else {
        return pcInstructions
    }
}

