import gallery from "../assets/models/gallery.gltf"
import bench from "../assets/models/bench.gltf"
import lights from "../assets/models/lights.gltf"
import wall from "../assets/img/wall.jpg"
import canvas from "../assets/img/canvas.jpg"
import door from "../assets/art/wooddoor.png"
import title from "../assets/art/title.jpg"

import logo1 from "../assets/art/karuna.jpg"
import logo2 from "../assets/art/lion.png"
import logo3 from "../assets/art/DWS.png"
import logo4 from "../assets/art/KATSN.jpg"

import art20 from "../assets/art/BAM/2.jpg"
import art21 from "../assets/art/C/2.jpg"
import art22 from "../assets/art/9.jpg"
import art23 from "../assets/art/D/3.jpg"
import art24 from "../assets/art/6.jpg"
import art25 from "../assets/art/DM/2.jpg"
import art26 from "../assets/art/KL/3.jpg"
import art27 from "../assets/art/LJR/3.jpg"
import art28 from "../assets/art/7.jpg"
import art29 from "../assets/art/M/2.jpg"
import art30 from "../assets/art/NC/3.jpg"
import art31 from "../assets/art/RL/2.jpg"
import art32 from "../assets/art/S/2.jpg"
import art33 from "../assets/art/KL/4.jpg"

// import art34 from "../assets/art/8.jpg"

import art34 from "../assets/art/LJR/4.jpg"
import art35 from "../assets/art/KL/5.jpg"
import art36 from "../assets/art/10.jpg"
import art37 from "../assets/art/KL/6.jpg"
import art38 from "../assets/art/BAM/3.jpg"

import { MeshPhongMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { imgContent, titleContent, nfsContent } from "helpers/modal"
import { getArtDetails, getArtName } from "./art2"
const fontLoader = new FontLoader()
export const playerProps = {
    mass: 50.0,
    height: 75
}
export const cameraProps = {
    lobby: {
        position : {
            x: 1700,
            y: playerProps.height,
            z: -200
        },
        rotate: {
            x: 0,
            y: 0.8 * Math.PI,
            z: 0
        }
    }
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	}
}

export const models = {
    lobby: {
        structure: gallery,
        bench1: bench,
        bench2: bench,
        bench3: bench,
        lights1: lights,
        lights2: lights,
        lights3: lights,
        lights4: lights,
        lights5: lights,
        lights6: lights,
        lights15: lights,
        lights16: lights,
        lights17: lights,
        lights18: lights,
        lights19: lights,
        lights20: lights,
    }
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    lobby: {
        block1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block3_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        canvas20_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas21_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas22_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas23_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas24_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas25_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas26_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas27_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas28_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas29_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas30_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas31_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas32_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas33_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        // canvas34_box: {
        //     path: canvas,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        canvas34_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas35_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas36_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas37_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas38_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        art20_plane: {
            path: art20,
            repeatX: 1,
            repeatY: 1
        },
        art21_plane: {
            path: art21,
            repeatX: 1,
            repeatY: 1
        },
        art22_plane: {
            path: art22,
            repeatX: 1,
            repeatY: 1
        },
        art23_plane: {
            path: art23,
            repeatX: 1,
            repeatY: 1
        },
        art24_plane: {
            path: art24,
            repeatX: 1,
            repeatY: 1
        },
		art25_plane: {
            path: art25,
            repeatX: 1,
            repeatY: 1
        },
		art26_plane: {
            path: art26,
            repeatX: 1,
            repeatY: 1
        },
		art27_plane: {
            path: art27,
            repeatX: 1,
            repeatY: 1
        },
		art28_plane: {
            path: art28,
            repeatX: 1,
            repeatY: 1
        },
		art29_plane: {
            path: art29,
            repeatX: 1,
            repeatY: 1
        },
		art30_plane: {
            path: art30,
            repeatX: 1,
            repeatY: 1
        },
		art31_plane: {
            path: art31,
            repeatX: 1,
            repeatY: 1
        },
        art32_plane: {
            path: art32,
            repeatX: 1,
            repeatY: 1
        },
		art33_plane: {
            path: art33,
            repeatX: 1,
            repeatY: 1
        },
		// art34_plane: {
        //     path: art34,
        //     repeatX: 1,
        //     repeatY: 1
        // },
		art34_plane: {
            path: art34,
            repeatX: 1,
            repeatY: 1
        },
		art35_plane: {
            path: art35,
            repeatX: 1,
            repeatY: 1
        },
		art36_plane: {
            path: art36,
            repeatX: 1,
            repeatY: 1
        },
		art37_plane: {
            path: art37,
            repeatX: 1,
            repeatY: 1
        },
		art38_plane: {
            path: art38,
            repeatX: 1,
            repeatY: 1
        },
        door_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door2_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door3_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door4_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        title_plane: {
            path: title,
            repeatX: 1,
            repeatY: 1
        },
        logo1_plane: {
            path: logo1,
            repeatX: 1,
            repeatY: 1
        },
        logo2_plane: {
            path: logo2,
            repeatX: 1,
            repeatY: 1
        },
        logo3_plane: {
            path: logo3,
            repeatX: 1,
            repeatY: 1
        },
        logo4_plane: {
            path: logo4,
            repeatX: 1,
            repeatY: 1
        },
    }
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value,
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    lobby: {
        block1: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side1: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block2: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side2: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block3: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        canvas20: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
        art20: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas21: {
            geometry: "box",
            width: 122,
            height: 90.4,
            depth: 4,
            material: null
        },
        art21: {
            geometry: "plane",
            width: 120,
            height: 88.4,
            material: null
        },
        canvas22: {
            geometry: "box",
            width: 88.6,
            height: 122,
            depth: 4,
            material: null
        },
        art22: {
            geometry: "plane",
            width: 86.6,
            height: 120,
            material: null
        },
        canvas23: {
            geometry: "box",
            width: 89.8,
            height: 122,
            depth: 4,
            material: null
        },
        art23: {
            geometry: "plane",
            width: 87.8,
            height: 120,
            material: null
        },
        canvas24: {
            geometry: "box",
            width: 122.0,
            height: 88.7,
            depth: 4,
            material: null
        },
		art24: {
            geometry: "plane",
            width: 120.0,
            height: 86.7,
            material: null
        },
        canvas25: {
            geometry: "box",
            width: 122,
            height: 92,
            depth: 4,
            material: null
        },
		art25: {
            geometry: "plane",
            width: 120,
            height: 90,
            material: null
        },
		canvas26: {
            geometry: "box",
            width: 122,
            height: 87.7,
            depth: 4,
            material: null
        },
		art26: {
            geometry: "plane",
            width: 120,
            height: 85.7,
            material: null
        },
		canvas27: {
            geometry: "box",
            width: 82,
            height: 122,
            depth: 4,
            material: null
        },
		art27: {
            geometry: "plane",
            width: 80,
            height: 120,
            material: null
        },
		canvas28: {
            geometry: "box",
            width: 122.0,
            height: 89.8,
            depth: 4,
            material: null
        },
		art28: {
            geometry: "plane",
            width: 120.0,
            height: 87.8,
            material: null
        },
		canvas29: {
            geometry: "box",
            width: 122,
            height: 88.2,
            depth: 4,
            material: null
        },
		art29: {
            geometry: "plane",
            width: 120,
            height: 86.2,
            material: null
        },
		canvas30: {
            geometry: "box",
            width: 80.9,
            height: 122,
            depth: 4,
            material: null
        },
		art30: {
            geometry: "plane",
            width: 78.9,
            height: 120,
            material: null
        },
        canvas31: {
            geometry: "box",
            width: 92.4,
            height: 122,
            depth: 4,
            material: null
        },
		art31: {
            geometry: "plane",
            width: 90.4,
            height: 120,
            material: null
        },
        canvas32: {
            geometry: "box",
            width: 92.3,
            height: 122,
            depth: 4,
            material: null
        },
		art32: {
            geometry: "plane",
            width: 90.3,
            height: 120,
            material: null
        },
		canvas33: {
            geometry: "box",
            width: 122,
            height: 87.7,
            depth: 4,
            material: null
        },
		art33: {
            geometry: "plane",
            width: 120,
            height: 85.7,
            material: null
        },
		// canvas34: {
        //     geometry: "box",
        //     width: 122,
        //     height: 88.5,
        //     depth: 4,
        //     material: null
        // },
		// art34: {
        //     geometry: "plane",
        //     width: 120,
        //     height: 86.5,
        //     material: null
        // },
		canvas34: {
            geometry: "box",
            width: 122,
            height: 98.9,
            depth: 4,
            material: null
        },
		art34: {
            geometry: "plane",
            width: 120,
            height: 96.9,
            material: null
        },
		canvas35: {
            geometry: "box",
            width: 87.7,
            height: 122,
            depth: 4,
            material: null
        },
		art35: {
            geometry: "plane",
            width: 85.7,
            height: 120,
            material: null
        },
		canvas36: {
            geometry: "box",
            width: 122.0,
            height: 82.0,
            depth: 4,
            material: null
        },
		art36: {
            geometry: "plane",
            width: 120.0,
            height: 80.0,
            material: null
        },
		canvas37: {
            geometry: "box",
            width: 122,
            height: 87.7,
            depth: 4,
            material: null
        },
		art37: {
            geometry: "plane",
            width: 120,
            height: 85.7,
            material: null
        },
		canvas38: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art38: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        door: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door2: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door3: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door4: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        title: {
            geometry: "plane",
            width: 118,
            height: 78,
            material: null
        },
        logo1: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo2: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo3: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo4: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        }
    }
}

export const lobbyProps = {
    structure: {
        scale: {
            x: 0.25,
            y: 0.2,
            z: 0.6
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    block1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1200,
            y: 75,
            z: 50
        }
    },
    side1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: 480
            // Z: +80
        }
    },
    block2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 600,
            y: 75,
            z: -50
        }
    },
    side2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: -490
        }
    },
    block3_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 0,
            y: 75,
            z: 0
        }
    },
    bench1: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 0,
            z: 0
        }
    },
    bench2: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    bench3: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 300,
            y: 0,
            z: 0
        }
    },
    lights1: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: 350
        }
    },
    lights2: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: 350
        }
    },
    lights3: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: 350
        }
    },
    lights4: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: 350
        }
    },
    lights5: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: 350
        }
    },
    lights6: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: 350
        }
    },
    lights15: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: -250
        }
    },
    lights16: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: -250
        }
    },
    lights17: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: -250
        }
    },
    lights18: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: -250
        }
    },
    lights19: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: -250
        }
    },
    lights20: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: -250
        }
    },
    canvas20_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 250
        }
    },
    art20_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 250
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art20, getArtDetails("20")),
                title: getArtName("20")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas21_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -160
        }
    },
    art21_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -160
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art21, getArtDetails("21")),
                title: getArtName("21")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas22_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -30
        }
    },
    art22_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -30
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art22, getArtDetails("22")),
                title: getArtName("22")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas23_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 100
        }
    },
    art23_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 100
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art23, getArtDetails("23")),
                title: getArtName("23")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas24_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 495
        }
    },
    art24_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art24, getArtDetails("24")),
                title: getArtName("24")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas25_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -495
        }
    },
    art25_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art25, getArtDetails("25")),
                title: getArtName("25")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas26_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -495
        }
    },
    art26_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art26, getArtDetails("26")),
                title: getArtName("26")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas27_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 495
        }
    },
    art27_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art27, getArtDetails("27")),
                title: getArtName("27")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas28_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -483
        }
    },
    art28_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art28, getArtDetails("28")),
                title: getArtName("28")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas29_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -483
        }
    },
    art29_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art29, getArtDetails("29")),
                title: getArtName("29")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas30_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 495
        }
    },
    art30_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art30, getArtDetails("30")),
                title: getArtName("30")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas31_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -495
        }
    },
    art31_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art31, getArtDetails("31")),
                title: getArtName("31")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas32_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -495
        }
    },
    art32_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art32, getArtDetails("32")),
                title: getArtName("32")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas33_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: 120
        }
    },
    art33_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: 120
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art33, getArtDetails("33")),
                title: getArtName("33")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // canvas34_box: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 608,
    //         y: 75,
    //         z: 20
    //     }
    // },
    // art34_plane: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 611,
    //         y: 75,
    //         z: 20
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: imgContent(art34, getArtDetails("15")),
    //             title: getArtName("15")
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    canvas34_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -60
        }
    },
    art34_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -60
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art34, getArtDetails("34")),
                title: getArtName("34")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas35_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -220
        }
    },
    art35_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -220
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art35, getArtDetails("35")),
                title: getArtName("35")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas36_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 495
        }
    },
    art36_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art36, getArtDetails("36")),
                title: getArtName("36")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas37_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 473
        }
    },
    art37_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art37, getArtDetails("37")),
                title: getArtName("37")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas38_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 473
        }
    },
    art38_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art38, getArtDetails("38")),
                title: getArtName("38")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryC"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryC"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryA"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryA"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    title_plane: {
        scale: {
            x: 1.2,
            y: 1.2,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 0
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: titleContent(title, getArtDetails("title")),
                title: getArtName("title")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo1_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://karunasarawak.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.lionsclubs.org/en"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.divinewillsociety.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.facebook.com/KATSNKuching/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}
