import { isAndroid, isIOS } from "react-device-detect"
import { getReserved } from "./queries"

export function OnWindowResize(camera, renderer) {
	console.log("window resized")
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
}

export function HandleMouseDown (e, movement) {
	console.log(e)
	console.log(movement)
	if (movement.enabled) {
		if (e.clientX < window.innerWidth / 3 * 1 && (isIOS || isAndroid)) {
			movement.moveLeft = true
		} else if (e.clientX > window.innerWidth / 3 * 2 && (isIOS || isAndroid)) {
			movement.moveRight = true
		}
	}
}

export function HandleOrient (e, movement) {
	movement.turnAngle = Math.floor(e.alpha) - movement.prevAngle
	movement.prevAngle = Math.floor(e.alpha)
}

export function HandleKeyDown (e, movement) {
	console.log(movement)
	switch (e.code) {
		case "ArrowUp":
			movement.moveForward = true
			break

		case "KeyW":
			movement.moveForward = true
			break

		case "ArrowLeft":
			movement.moveLeft = true
			break

		case "KeyA":
			movement.moveLeft = true
			break

		case "ArrowDown":
			movement.moveBackward = true
			break

		case "KeyS":
			movement.moveBackward = true
			break

		case "ArrowRight":
			movement.moveRight = true
			break

		case "KeyD":
			movement.moveRight = true
			break
	}
}

export function HandleKeyUp (e, movement) {
    switch (e.code) {
		case "ArrowUp":
			movement.moveForward = false
			break

		case "KeyW":
			movement.moveForward = false
			break

		case "ArrowLeft":
			movement.moveLeft = false
			break

		case "KeyA":
			movement.moveLeft = false
			break

		case "ArrowDown":
			movement.moveBackward = false
			break

		case "KeyS":
			movement.moveBackward = false
			break

		case "ArrowRight":
			movement.moveRight = false
			break

		case "KeyD":
			movement.moveRight = false
			break
    }
}