import { child, get, getDatabase, onValue, ref, set } from "firebase/database"
import app from "./firebase"

export const getReservations = () => {
    const dbRef = ref(getDatabase(app))
    get(child(dbRef, 'reservations')).then((r) => {
        return r.val()
    })
}

export const addReservation = (artId, callback) => {
    console.log(artId)
    const db = getDatabase(app)
    const dbRef = ref(getDatabase(app))
    let orderId = new Date().getTime()
    get(child(dbRef, 'reservations/' + artId)).then((r) => {
        var reservations = r.val()
        if (reservations.length < 1) {
            reservations = {}
        }
        reservations[orderId] = {
            status: 'active'
        }
        set(ref(db, 'reservations/' + artId), reservations).then(() => {
            console.log('reservation added')
            callback(orderId)
        })
    })
}

export const updateReservation = (reservationId, artId, shippingDetails) => {
    console.log(artId, shippingDetails)
    const db = getDatabase(app)
    const dbRef = ref(getDatabase(app))
    get(child(dbRef, 'reservations/' + artId)).then((r) => {
        var reservations = r.val()
        if (reservations.length < 1) {
            reservations = {}
        }
        reservations[reservationId] = {
            status: 'active',
            shippingDetails
        }
        set(ref(db, 'reservations/' + artId), reservations).then(() => {
            console.log('reservation updated')
        })
    })
}

export const checkArtStatus = (artId, buttonId) => {
    if (document.getElementById(buttonId)) {
        let reserved = false
        let purchased = false
        const dbRef = ref(getDatabase(app))
        console.log(artId)
        get(child(dbRef, 'reservations/' + artId)).then((r) => {
            let reservations = r.val()
            if (Object.keys(reservations).length > 0) {
                for (var rid in reservations) {
                    let currentTime = new Date().getTime()
                    console.log(currentTime - rid)
                    if (reservations[rid].status == 'active' && currentTime - rid < 900000) {
                        reserved = true
                        document.getElementById(buttonId).setAttribute('class', 'btn-round mx-2 btn btn-warning')
                        document.getElementById(buttonId).innerHTML = 'Reserved'
                        document.getElementById(buttonId).disabled = true
                    }
                }
            }
            get(child(dbRef, 'purchases/' + artId)).then((p) => {
                let purchases = p.val()
                if (purchases != null) {
                    purchased = true
                    document.getElementById(buttonId).setAttribute('class', 'btn-round mx-2 btn btn-primary')
                    document.getElementById(buttonId).innerHTML = 'Sold'
                    document.getElementById(buttonId).disabled = true
                }
    
                if (!reserved && !purchased) {
                    document.getElementById(buttonId).setAttribute('class', 'btn-round mx-2 btn btn-success')
                    document.getElementById(buttonId).innerHTML = 'Purchase'
                    document.getElementById(buttonId).disabled = false
                }
            })
        })
    }
}

export const addPurchaseRecord = (artId, paymentDetails) => {
    const db = getDatabase(app)
    const dbRef = ref(getDatabase(app))
    get(child(dbRef, 'purchases')).then((p) => {
        var purchases = p.val()
        console.log(purchases)
        if (purchases == "") {
            purchases = {}
        }
        purchases[artId] = {
            paymentDetails
        }
        set(ref(db, 'purchases'), purchases).then(() => {
            console.log('purchase added')
        })
    })
}