import gallery from "../assets/models/gallery.gltf"
import bench from "../assets/models/bench.gltf"
import lights from "../assets/models/lights.gltf"
import wall from "../assets/img/wall.jpg"
import canvas from "../assets/img/canvas.jpg"
import door from "../assets/art/wooddoor.png"
import title from "../assets/art/title.jpg"

import logo1 from "../assets/art/karuna.jpg"
import logo2 from "../assets/art/lion.png"
import logo3 from "../assets/art/DWS.png"
import logo4 from "../assets/art/KATSN.jpg"

import art58 from "../assets/art/D/6.jpg"
import art59 from "../assets/art/LJR/7.jpg"
import art60 from "../assets/art/18.jpg"
import art61 from "../assets/art/BAM/6.jpg"
import art62 from "../assets/art/16.jpg"
import art63 from "../assets/art/NC/6.jpg"
import art64 from "../assets/art/NC/7.jpg"
import art65 from "../assets/art/KL/14.jpg"
import art66 from "../assets/art/17.jpg"
import art67 from "../assets/art/RL/4.jpg"
import art68 from "../assets/art/S/4.jpg"
import art69 from "../assets/art/KL/12.jpg"
import art70 from "../assets/art/LJR/8.jpg"
import art71 from "../assets/art/KL/13.jpg"

// import art71 from "../assets/art/19.jpg"

import art72 from "../assets/art/S/5.jpg"
import art73 from "../assets/art/KL/15.jpg"
import art74 from "../assets/art/20.jpg"
import art75 from "../assets/art/KL/16.jpg"
import art76 from "../assets/art/KL/17.jpg"

import { MeshPhongMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { imgContent, titleContent } from "helpers/modal"
import { getArtDetails, getArtName } from "./art4"
const fontLoader = new FontLoader()
export const playerProps = {
    mass: 50.0,
    height: 75
}
export const cameraProps = {
    lobby: {
        position : {
            x: 1700,
            y: playerProps.height,
            z: -200
        },
        rotate: {
            x: 0,
            y: 0.8 * Math.PI,
            z: 0
        }
    }
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	}
}

export const models = {
    lobby: {
        structure: gallery,
        bench1: bench,
        bench2: bench,
        bench3: bench,
        lights1: lights,
        lights2: lights,
        lights3: lights,
        lights4: lights,
        lights5: lights,
        lights6: lights,
        lights15: lights,
        lights16: lights,
        lights17: lights,
        lights18: lights,
        lights19: lights,
        lights20: lights,
    }
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    lobby: {
        block1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block3_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        canvas57_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas58_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas59_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas60_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas61_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas62_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas63_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas65_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas66_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas67_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas68_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas69_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas70_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas71_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        // canvas15_box: {
        //     path: canvas,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        canvas72_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas73_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas74_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas75_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas76_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        art58_plane: {
            path: art58,
            repeatX: 1,
            repeatY: 1
        },
        art59_plane: {
            path: art59,
            repeatX: 1,
            repeatY: 1
        },
        art60_plane: {
            path: art60,
            repeatX: 1,
            repeatY: 1
        },
        art61_plane: {
            path: art61,
            repeatX: 1,
            repeatY: 1
        },
        art62_plane: {
            path: art62,
            repeatX: 1,
            repeatY: 1
        },
		art63_plane: {
            path: art63,
            repeatX: 1,
            repeatY: 1
        },
		art64_plane: {
            path: art64,
            repeatX: 1,
            repeatY: 1
        },
		art65_plane: {
            path: art65,
            repeatX: 1,
            repeatY: 1
        },
		art66_plane: {
            path: art66,
            repeatX: 1,
            repeatY: 1
        },
		art67_plane: {
            path: art67,
            repeatX: 1,
            repeatY: 1
        },
		art68_plane: {
            path: art68,
            repeatX: 1,
            repeatY: 1
        },
		art69_plane: {
            path: art69,
            repeatX: 1,
            repeatY: 1
        },
        art70_plane: {
            path: art70,
            repeatX: 1,
            repeatY: 1
        },
		art71_plane: {
            path: art71,
            repeatX: 1,
            repeatY: 1
        },
		// art71_plane: {
        //     path: art71,
        //     repeatX: 1,
        //     repeatY: 1
        // },
		art72_plane: {
            path: art72,
            repeatX: 1,
            repeatY: 1
        },
		art73_plane: {
            path: art73,
            repeatX: 1,
            repeatY: 1
        },
		art74_plane: {
            path: art74,
            repeatX: 1,
            repeatY: 1
        },
		art75_plane: {
            path: art75,
            repeatX: 1,
            repeatY: 1
        },
		art76_plane: {
            path: art76,
            repeatX: 1,
            repeatY: 1
        },
        door_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door2_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door3_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door4_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        title_plane: {
            path: title,
            repeatX: 1,
            repeatY: 1
        },
        logo1_plane: {
            path: logo1,
            repeatX: 1,
            repeatY: 1
        },
        logo2_plane: {
            path: logo2,
            repeatX: 1,
            repeatY: 1
        },
        logo3_plane: {
            path: logo3,
            repeatX: 1,
            repeatY: 1
        },
        logo4_plane: {
            path: logo4,
            repeatX: 1,
            repeatY: 1
        },
    }
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value,
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    lobby: {
        block1: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side1: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block2: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side2: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block3: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        canvas57: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
        art58: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas58: {
            geometry: "box",
            width: 122,
            height: 86,
            depth: 4,
            material: null
        },
        art59: {
            geometry: "plane",
            width: 120,
            height: 84,
            material: null
        },
        canvas59: {
            geometry: "box",
            width: 89.6,
            height: 122,
            depth: 4,
            material: null
        },
        art60: {
            geometry: "plane",
            width: 87.6,
            height: 120,
            material: null
        },
        canvas60: {
            geometry: "box",
            width: 91.4,
            height: 122,
            depth: 4,
            material: null
        },
        art61: {
            geometry: "plane",
            width: 89.4,
            height: 120,
            material: null
        },
        canvas61: {
            geometry: "box",
            width: 122.0,
            height: 91.8,
            depth: 4,
            material: null
        },
		art62: {
            geometry: "plane",
            width: 120.0,
            height: 89.8,
            material: null
        },
        canvas62: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art63: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		canvas63: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art64: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		canvas65: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art65: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		canvas66: {
            geometry: "box",
            width: 122.0,
            height: 90.5,
            depth: 4,
            material: null
        },
		art66: {
            geometry: "plane",
            width: 120.0,
            height: 88.5,
            material: null
        },
		canvas67: {
            geometry: "box",
            width: 93.5,
            height: 122,
            depth: 4,
            material: null
        },
		art67: {
            geometry: "plane",
            width: 91.5,
            height: 120,
            material: null
        },
		canvas68: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art68: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas69: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art69: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        canvas70: {
            geometry: "box",
            width: 122,
            height: 92.2,
            depth: 4,
            material: null
        },
		art70: {
            geometry: "plane",
            width: 120,
            height: 90.2,
            material: null
        },
		canvas71: {
            geometry: "box",
            width: 122,
            height: 87.7,
            depth: 4,
            material: null
        },
		art71: {
            geometry: "plane",
            width: 120,
            height: 85.7,
            material: null
        },
		// canvas575: {
        //     geometry: "box",
        //     width: 122,
        //     height: 90.2,
        //     depth: 4,
        //     material: null
        // },
		// art71: {
        //     geometry: "plane",
        //     width: 120,
        //     height: 88.2,
        //     material: null
        // },
		canvas72: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art72: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
		canvas73: {
            geometry: "box",
            width: 82,
            height: 122,
            depth: 4,
            material: null
        },
		art73: {
            geometry: "plane",
            width: 80,
            height: 120,
            material: null
        },
		canvas74: {
            geometry: "box",
            width: 122.0,
            height: 87.8,
            depth: 4,
            material: null
        },
		art74: {
            geometry: "plane",
            width: 120.0,
            height: 85.8,
            material: null
        },
		canvas75: {
            geometry: "box",
            width: 122,
            height: 104.9,
            depth: 4,
            material: null
        },
		art75: {
            geometry: "plane",
            width: 120,
            height: 102.9,
            material: null
        },
		canvas76: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art76: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        door: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door2: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door3: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door4: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        title: {
            geometry: "plane",
            width: 118,
            height: 78,
            material: null
        },
        logo1: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo2: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo3: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo4: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        }
    }
}

export const lobbyProps = {
    structure: {
        scale: {
            x: 0.25,
            y: 0.2,
            z: 0.6
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    block1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1200,
            y: 75,
            z: 50
        }
    },
    side1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: 480
            // Z: +80
        }
    },
    block2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 600,
            y: 75,
            z: -50
        }
    },
    side2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: -490
        }
    },
    block3_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 0,
            y: 75,
            z: 0
        }
    },
    bench1: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 0,
            z: 0
        }
    },
    bench2: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    bench3: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 300,
            y: 0,
            z: 0
        }
    },
    lights1: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: 350
        }
    },
    lights2: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: 350
        }
    },
    lights3: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: 350
        }
    },
    lights4: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: 350
        }
    },
    lights5: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: 350
        }
    },
    lights6: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: 350
        }
    },
    lights15: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: -250
        }
    },
    lights16: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: -250
        }
    },
    lights17: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: -250
        }
    },
    lights18: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: -250
        }
    },
    lights19: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: -250
        }
    },
    lights20: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: -250
        }
    },
    canvas57_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 250
        }
    },
    art58_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 250
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art58, getArtDetails("58")),
                title: getArtName("58")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas58_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -160
        }
    },
    art59_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -160
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art59, getArtDetails("59")),
                title: getArtName("59")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas59_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -30
        }
    },
    art60_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -30
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art60, getArtDetails("60")),
                title: getArtName("60")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas60_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 100
        }
    },
    art61_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 100
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art61, getArtDetails("61")),
                title: getArtName("61")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas61_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 495
        }
    },
    art62_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art62, getArtDetails("62")),
                title: getArtName("62")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas62_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -495
        }
    },
    art63_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art63, getArtDetails("63")),
                title: getArtName("63")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas63_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -495
        }
    },
    art64_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art64, getArtDetails("64")),
                title: getArtName("64")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas65_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 495
        }
    },
    art65_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art65, getArtDetails("65")),
                title: getArtName("65")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas66_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -483
        }
    },
    art66_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art66, getArtDetails("66")),
                title: getArtName("66")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas67_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -483
        }
    },
    art67_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art67, getArtDetails("67")),
                title: getArtName("67")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas68_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 495
        }
    },
    art68_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art68, getArtDetails("68")),
                title: getArtName("68")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas69_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -495
        }
    },
    art69_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art69, getArtDetails("69")),
                title: getArtName("69")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas70_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -495
        }
    },
    art70_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art70, getArtDetails("70")),
                title: getArtName("70")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas71_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: 120
        }
    },
    art71_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: 120
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art71, getArtDetails("71")),
                title: getArtName("71")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // canvas575_box: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 608,
    //         y: 75,
    //         z: 20
    //     }
    // },
    // art71_plane: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 611,
    //         y: 75,
    //         z: 20
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: imgContent(art71, getArtDetails("15")),
    //             title: getArtName("15")
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    canvas72_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -60
        }
    },
    art72_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -60
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art72, getArtDetails("72")),
                title: getArtName("72")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas73_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -220
        }
    },
    art73_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -220
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art73, getArtDetails("73")),
                title: getArtName("73")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas74_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 495
        }
    },
    art74_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art74, getArtDetails("74")),
                title: getArtName("74")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas75_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 473
        }
    },
    art75_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art75, getArtDetails("75")),
                title: getArtName("75")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas76_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 473
        }
    },
    art76_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art76, getArtDetails("76")),
                title: getArtName("76")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryE"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryE"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryC"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryC"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    title_plane: {
        scale: {
            x: 1.2,
            y: 1.2,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 0
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: titleContent(title, getArtDetails("title")),
                title: getArtName("title")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo1_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://karunasarawak.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.lionsclubs.org/en"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.divinewillsociety.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.facebook.com/KATSNKuching/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}
