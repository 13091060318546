import { Component } from 'react';
import background from '../assets/img/tqbg.webp'

export default class ThankYou extends Component {
    componentDidMount() {
    }
    constructor (props) {
        super(props)
        this.state = {
        }
        
    }
    render() {
        return (
            <>
            <div
                style={{
                    backgroundImage: 'url(' + background + ')',
                    paddingTop: '30vh',
                    height: '100vh',
                    textAlign: 'center',
                }}
            >
                <h1
                    style={{
                        color: '#222a42'
                    }}
                >
                    Thank you for your purchase!
                </h1>
                <p
                    style={{
                        color: '#222a42'
                    }}
                >
                    There is nothing more beautiful than someone who goes out of their way to make life beautiful for others.
                    <br/>
                    <i>Mandy Hale</i>
                </p>
            </div>
            </>
        )
    }
}