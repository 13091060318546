import { addPurchaseRecord } from "helpers/queries"
import { useParams } from "react-router-dom"

export const FNXCallBack = () => {
    const {artId} = useParams()
    console.log(artId)
    console.log(window.location.href)
    let paymentResp = new URLSearchParams(window.location.search)
    let respJson = Object.fromEntries(paymentResp)
    addPurchaseRecord(artId, respJson)
    // let respJson = {
    //     MerchantID: paymentResp.get("MerchantID"),
    //     CardNo: paymentResp.get("CardNo"),
    //     ExpiryYear: paymentResp.get("ExpiryYear"),
    //     ExpiryMth: paymentResp.get("ExpiryMth"),
    //     TxnDtTm: paymentResp.get("TxnDtTm"),
    //     TxnAmt: paymentResp.get("TxnAmt"),
    //     TxnStatus: paymentResp.get("TxnStatus"),
    //     CurrCode: paymentResp.get("CurrCode"),
    //     AuthIDRespCode: paymentResp.get("AuthIDRespCode"),
    //     ExpTxnAmt: paymentResp.get("ExpTxnAmt"),
    //     RetrievalRefNo: paymentResp.get("RetrievalRefNo"),
    //     OrigRespCode: paymentResp.get("OriRespCode"),
    //     DDRespCode: paymentResp.get("DDRespCode"),
    //     FICode: paymentResp.get("FICode"),
    //     PymtGwRefNo: paymentResp.get("PymtGwRefNo"),
    //     TxnStatDetCde: paymentResp.get("TxnStatDetCde"),
    //     TxnStatMsg: paymentResp.get("TxnStatMsg"),
    //     MerchHostID: paymentResp.get("MerchHostID"),
    //     MerchSessionID: paymentResp.get("MerchSessionID"),
    //     MerchRefNo: paymentResp.get("MerchRefNo"),
    //     OrderRefNo: paymentResp.get("OrderRefNo"),
    //     FPXRefNo: paymentResp.get("FPXRefNo"),
    //     UppPymtMode: paymentResp.get("Upp")
    // }

    return (
        <></>
    )
}