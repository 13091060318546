import gallery from "../assets/models/gallery.gltf"
import bench from "../assets/models/bench.gltf"
import lights from "../assets/models/lights.gltf"
import wall from "../assets/img/wall.jpg"
import canvas from "../assets/img/canvas.jpg"
import door from "../assets/art/wooddoor.png"
import title from "../assets/art/title.jpg"

import logo1 from "../assets/art/karuna.jpg"
import logo2 from "../assets/art/lion.png"
import logo3 from "../assets/art/DWS.png"
import logo4 from "../assets/art/KATSN.jpg"

import art77 from "../assets/art/NC/8.jpg"
import art78 from "../assets/art/RL/5.jpg"
import art79 from "../assets/art/21.jpg"
import art80 from "../assets/art/S/6.jpg"
import art81 from "../assets/art/22.jpg"
import art82 from "../assets/art/BAM/7.jpg"
import art83 from "../assets/art/D/7.jpg"
import art84 from "../assets/art/KL/18.jpg"
import art85 from "../assets/art/24.jpg"
import art86 from "../assets/art/LJR/9.jpg"
import art87 from "../assets/art/KL/19.jpg"
import art88 from "../assets/art/BAM/8.jpg"
import art89 from "../assets/art/KL/20.jpg"
import art90 from "../assets/art/KL/23.jpg"

// import art775 from "../assets/art/25.jpg"

import art91 from "../assets/art/LJR/10.jpg"
import art92 from "../assets/art/KL/21.jpg"
import art93 from "../assets/art/23.jpg"
import art94 from "../assets/art/NC/9.jpg"
import art95 from "../assets/art/KL/22.jpg"

import { MeshPhongMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { imgContent, titleContent } from "helpers/modal"
import { getArtDetails, getArtName } from "./art5"
const fontLoader = new FontLoader()
export const playerProps = {
    mass: 50.0,
    height: 75
}
export const cameraProps = {
    lobby: {
        position : {
            x: 1700,
            y: playerProps.height,
            z: -200
        },
        rotate: {
            x: 0,
            y: 0.8 * Math.PI,
            z: 0
        }
    }
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	}
}

export const models = {
    lobby: {
        structure: gallery,
        bench1: bench,
        bench2: bench,
        bench3: bench,
        lights1: lights,
        lights2: lights,
        lights3: lights,
        lights4: lights,
        lights5: lights,
        lights6: lights,
        lights15: lights,
        lights16: lights,
        lights17: lights,
        lights18: lights,
        lights19: lights,
        lights20: lights,
    }
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    lobby: {
        block1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side1_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        side2_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        block3_box: {
            path: wall,
            repeatX: 1,
            repeatY: 1
        },
        canvas77_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas78_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas79_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas80_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas81_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas82_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas83_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas84_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas85_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas86_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas87_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas88_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas89_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas90_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        // canvas775_box: {
        //     path: canvas,
        //     repeatX: 1,
        //     repeatY: 1
        // },
        canvas91_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas92_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas93_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas94_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        canvas95_box: {
            path: canvas,
            repeatX: 1,
            repeatY: 1
        },
        art77_plane: {
            path: art77,
            repeatX: 1,
            repeatY: 1
        },
        art78_plane: {
            path: art78,
            repeatX: 1,
            repeatY: 1
        },
        art79_plane: {
            path: art79,
            repeatX: 1,
            repeatY: 1
        },
        art80_plane: {
            path: art80,
            repeatX: 1,
            repeatY: 1
        },
        art81_plane: {
            path: art81,
            repeatX: 1,
            repeatY: 1
        },
		art82_plane: {
            path: art82,
            repeatX: 1,
            repeatY: 1
        },
		art83_plane: {
            path: art83,
            repeatX: 1,
            repeatY: 1
        },
		art84_plane: {
            path: art84,
            repeatX: 1,
            repeatY: 1
        },
		art85_plane: {
            path: art85,
            repeatX: 1,
            repeatY: 1
        },
		art86_plane: {
            path: art86,
            repeatX: 1,
            repeatY: 1
        },
		art87_plane: {
            path: art87,
            repeatX: 1,
            repeatY: 1
        },
		art88_plane: {
            path: art88,
            repeatX: 1,
            repeatY: 1
        },
        art89_plane: {
            path: art89,
            repeatX: 1,
            repeatY: 1
        },
		art90_plane: {
            path: art90,
            repeatX: 1,
            repeatY: 1
        },
		// art775_plane: {
        //     path: art775,
        //     repeatX: 1,
        //     repeatY: 1
        // },
		art91_plane: {
            path: art91,
            repeatX: 1,
            repeatY: 1
        },
		art92_plane: {
            path: art92,
            repeatX: 1,
            repeatY: 1
        },
		art93_plane: {
            path: art93,
            repeatX: 1,
            repeatY: 1
        },
		art94_plane: {
            path: art94,
            repeatX: 1,
            repeatY: 1
        },
		art95_plane: {
            path: art95,
            repeatX: 1,
            repeatY: 1
        },
        door_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door2_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door3_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        door4_plane: {
            path: door,
            repeatX: 1,
            repeatY: 1
        },
        title_plane: {
            path: title,
            repeatX: 1,
            repeatY: 1
        },
        logo1_plane: {
            path: logo1,
            repeatX: 1,
            repeatY: 1
        },
        logo2_plane: {
            path: logo2,
            repeatX: 1,
            repeatY: 1
        },
        logo3_plane: {
            path: logo3,
            repeatX: 1,
            repeatY: 1
        },
        logo4_plane: {
            path: logo4,
            repeatX: 1,
            repeatY: 1
        },
    }
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value,
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    lobby: {
        block1: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side1: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block2: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        side2: {
            geometry: "box",
            width: 400,
            height: 150,
            depth: 10,
            material: null
        },
        block3: {
            geometry: "box",
            width: 10,
            height: 150,
            depth: 600,
            material: null
        },
        canvas77: {
            geometry: "box",
            wwidth: 120,
            height: 80,
            depth: 4,
            material: null
        },
        art77: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
        canvas78: {
            geometry: "box",
            width: 122,
            height: 89.6,
            depth: 4,
            material: null
        },
        art78: {
            geometry: "plane",
            width: 120,
            height: 87.6,
            material: null
        },
        canvas79: {
            geometry: "box",
            width: 89.7,
            height: 122,
            depth: 4,
            material: null
        },
        art79: {
            geometry: "plane",
            width: 87.7,
            height: 120,
            material: null
        },
        canvas80: {
            geometry: "box",
            width: 92.2,
            height: 122,
            depth: 4,
            material: null
        },
        art80: {
            geometry: "plane",
            width: 90.2,
            height: 120,
            material: null
        },
        canvas81: {
            geometry: "box",
            width: 122.0,
            height: 90.7,
            depth: 4,
            material: null
        },
		art81: {
            geometry: "plane",
            width: 120.0,
            height: 88.7,
            material: null
        },
        canvas82: {
            geometry: "box",
            width: 89.9,
            height: 122,
            depth: 4,
            material: null
        },
		art82: {
            geometry: "plane",
            width: 87.9,
            height: 120,
            material: null
        },
		canvas83: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art83: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
		canvas84: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art84: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
		canvas85: {
            geometry: "box",
            width: 122.0,
            height: 91.7,
            depth: 4,
            material: null
        },
		art85: {
            geometry: "plane",
            width: 120.0,
            height: 89.7,
            material: null
        },
		canvas86: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art86: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		canvas87: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art87: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
        canvas88: {
            geometry: "box",
            width: 92.9,
            height: 122,
            depth: 4,
            material: null
        },
		art88: {
            geometry: "plane",
            width: 90.9,
            height: 120,
            material: null
        },
        canvas89: {
            geometry: "box",
            width: 122,
            height: 102,
            depth: 4,
            material: null
        },
		art89: {
            geometry: "plane",
            width: 120,
            height: 100,
            material: null
        },
		canvas90: {
            geometry: "box",
            width: 122,
            height: 82,
            depth: 4,
            material: null
        },
		art90: {
            geometry: "plane",
            width: 120,
            height: 80,
            material: null
        },
		// canvas775: {
        //     geometry: "box",
        //     width: 122,
        //     height: 89.1,
        //     depth: 4,
        //     material: null
        // },
		// art775: {
        //     geometry: "plane",
        //     width: 120,
        //     height: 87.1,
        //     material: null
        // },
		canvas91: {
            geometry: "box",
            width: 122,
            height: 95.5,
            depth: 4,
            material: null
        },
		art91: {
            geometry: "plane",
            width: 120,
            height: 93.5,
            material: null
        },
		canvas92: {
            geometry: "box",
            width: 122,
            height: 102,
            depth: 4,
            material: null
        },
		art92: {
            geometry: "plane",
            width: 120,
            height: 100,
            material: null
        },
		canvas93: {
            geometry: "box",
            width: 84.6,
            height: 122.0,
            depth: 4,
            material: null
        },
		art93: {
            geometry: "plane",
            width: 84.6,
            height: 120.0,
            material: null
        },
		canvas94: {
            geometry: "box",
            width: 122,
            height: 80.8,
            depth: 4,
            material: null
        },
		art94: {
            geometry: "plane",
            width: 120,
            height: 78.8,
            material: null
        },
		canvas95: {
            geometry: "box",
            width: 122,
            height: 122,
            depth: 4,
            material: null
        },
		art95: {
            geometry: "plane",
            width: 120,
            height: 120,
            material: null
        },
        door: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door2: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door3: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        door4: {
            geometry: "plane",
            width: 58,
            height: 118,
            material: null
        },
        title: {
            geometry: "plane",
            width: 118,
            height: 78,
            material: null
        },
        logo1: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo2: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo3: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        },
        logo4: {
            geometry: "plane",
            width: 80,
            height: 80,
            material: null
        }
    }
}

export const lobbyProps = {
    structure: {
        scale: {
            x: 0.25,
            y: 0.2,
            z: 0.6
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    block1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1200,
            y: 75,
            z: 50
        }
    },
    side1_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: 480
            // Z: +80
        }
    },
    block2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 600,
            y: 75,
            z: -50
        }
    },
    side2_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 900,
            y: 75,
            z: -490
        }
    },
    block3_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 0,
            y: 75,
            z: 0
        }
    },
    bench1: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 0,
            z: 0
        }
    },
    bench2: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 900,
            y: 0,
            z: 0
        }
    },
    bench3: {
        scale: {
            x: 80,
            y: 80,
            z: 80
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 300,
            y: 0,
            z: 0
        }
    },
    lights1: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: 350
        }
    },
    lights2: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: 350
        }
    },
    lights3: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: 350
        }
    },
    lights4: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: 350
        }
    },
    lights5: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: 350
        }
    },
    lights6: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: 350
        }
    },
    lights15: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1500,
            y: 205,
            z: -250
        }
    },
    lights16: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1485,
            y: 205,
            z: -250
        }
    },
    lights17: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 205,
            z: -250
        }
    },
    lights18: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 985,
            y: 205,
            z: -250
        }
    },
    lights19: {
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 500,
            y: 205,
            z: -250
        }
    },
    lights20: {
        scale: {
            x: 10,
            y: 10,
            z: -10
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 485,
            y: 205,
            z: -250
        }
    },
    canvas77_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 250
        }
    },
    art77_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 250
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art77, getArtDetails("77")),
                title: getArtName("77")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas78_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -160
        }
    },
    art78_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -160
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art78, getArtDetails("78")),
                title: getArtName("78")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas79_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: -30
        }
    },
    art79_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: -30
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art79, getArtDetails("79")),
                title: getArtName("79")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas80_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1208,
            y: 75,
            z: 100
        }
    },
    art80_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1211,
            y: 75,
            z: 100
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art80, getArtDetails("80")),
                title: getArtName("80")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas81_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 495
        }
    },
    art81_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1595,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art81, getArtDetails("81")),
                title: getArtName("81")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas82_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -495
        }
    },
    art82_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1640,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art82, getArtDetails("82")),
                title: getArtName("82")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas83_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -495
        }
    },
    art83_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art83, getArtDetails("83")),
                title: getArtName("83")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas84_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 495
        }
    },
    art84_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1410,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art84, getArtDetails("84")),
                title: getArtName("84")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas85_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -483
        }
    },
    art85_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 990,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art85, getArtDetails("85")),
                title: getArtName("85")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas86_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -483
        }
    },
    art86_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 810,
            y: 75,
            z: -480.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art86, getArtDetails("86")),
                title: getArtName("86")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas87_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 495
        }
    },
    art87_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 220,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art87, getArtDetails("87")),
                title: getArtName("87")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas88_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -495
        }
    },
    art88_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 260,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art88, getArtDetails("88")),
                title: getArtName("88")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas89_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -495
        }
    },
    art89_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 500,
            y: 85,
            z: -492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art89, getArtDetails("89")),
                title: getArtName("89")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas90_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: 120
        }
    },
    art90_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: 120
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art90, getArtDetails("90")),
                title: getArtName("90")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    // canvas775_box: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 608,
    //         y: 75,
    //         z: 20
    //     }
    // },
    // art775_plane: {
    //     scale: {
    //         x: 1,
    //         y: 1,
    //         z: 1
    //     },
    //     rotate: {
    //         x: 0,
    //         y: 0.5 * Math.PI,
    //         z: 0
    //     },
    //     position: {
    //         x: 611,
    //         y: 75,
    //         z: 20
    //     },
    //     interactions: [
    //         {
    //             action: "click",
    //             handler: "modal",
    //             content: imgContent(art775, getArtDetails("15")),
    //             title: getArtName("15")
    //         },
    //         {
    //             action: "mouseover",
    //             handler: () => {document.body.style.cursor = "pointer"}
    //         },
    //         {
    //             action: "mouseout",
    //             handler: () => {document.body.style.cursor = "default"}
    //         }
    //     ]
    // },
    canvas91_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -60
        }
    },
    art91_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -60
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art91, getArtDetails("91")),
                title: getArtName("91")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas92_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 608,
            y: 75,
            z: -220
        }
    },
    art92_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 611,
            y: 75,
            z: -220
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art92, getArtDetails("92")),
                title: getArtName("92")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas93_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 495
        }
    },
    art93_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 450,
            y: 80,
            z: 492.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art93, getArtDetails("93")),
                title: getArtName("93")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas94_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 473
        }
    },
    art94_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 1000,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art94, getArtDetails("94")),
                title: getArtName("94")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    canvas95_box: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 473
        }
    },
    art95_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0
        },
        position: {
            x: 790,
            y: 75,
            z: 470.9
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: imgContent(art95, getArtDetails("95")),
                title: getArtName("95")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryF"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -1.5,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryF"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: 400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryD"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    door4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 1800,
            y: 55,
            z: -400
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/galleryD"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    title_plane: {
        scale: {
            x: 1.2,
            y: 1.2,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 0
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: titleContent(title, getArtDetails("title")),
                title: getArtName("title")
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo1_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://karunasarawak.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo2_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -230
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.lionsclubs.org/en"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo3_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: 130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.divinewillsociety.com/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    logo4_plane: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: 9.1,
            y: 75,
            z: -130
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "https://www.facebook.com/KATSNKuching/"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}
