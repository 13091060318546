const pieces = [
    {
        name: "Rising Elegance",
        filename: "1",
        price: "200",
        artist: "Bertrand Arthur Morni",
        size: "18cm x 24cm",
        profile: "My name is Bertrand Arthur Morni. I am 17 years old. and diagnosed  with ADHD, Speech Delay and a Slow Learner.  Presently  studying in SMK Tabuan Jaya, PPKI, Kuching. I have a passion in any form of arts. I like to play with colours too.",
        media: "Pouring Art"
    },
    {
        name: "Totoro",
        filename: "2",
        price: "100",
        artist: "Brian Lin Wei Chiang",
        size: "25cm x 18cm",
        profile: "Brian Lin Wei Chiang, 18 years old with Dyslexia, DLD.",
        media: "Watercolour"
    },
    {
        name: "Colourful Flowers",
        filename: "3",
        price: "300",
        artist: "Darryon Leong",
        size: "28cm x 38cm",
        profile: "Darryon Leong Kai Ming, a 11 years old was an autism and ADHD kid. He is good sense of color and like to draw colorful art works. He often use watercolor as his medium in his drawings and paintings.",
        media: "Watercolour"
    },
    {
        name: "Squidward",
        filename: "4",
        price: "200",
        artist: "Carson Chang Kai Sheng",
        size: "38cm x 28cm",
        profile: "Carson Chang Kai Sheng, is a ten years old boy. He likes drawing and handcrafts. Some of his drawing was inspired by his favourite cartoon, Squidward. Besides that, he also likes to watch Pirate of the Caribbean. So that, he comes out with another drawing with Kraken attacks Black Pearls.",
        media: "Watercolour"
    },
    {
        name: "Bird",
        filename: "5",
        price: "300",
        artist: "Darryon Leong",
        size: "28cm x 38cm",
        profile: "Darryon Leong Kai Ming, a 11 years old was an autism and ADHD kid. He is good sense of color and like to draw colorful art works. He often use watercolor as his medium in his drawings and paintings.",
        media: "Watercolour"
    },
    {
        name: "Parrot",
        filename: "6",
        price: "Not for sale",
        artist: "Dave Hwang",
        size: "",
        profile: "My name is Dave Hwang. I'm 14 years old. I'm intellectual.<br/>I like drawing, photography and learning something new skills from online.",
        media: "Acrylic"
    },
    {
        name: "African Grey Parrot",
        filename: "7",
        price: "600",
        artist: "Kelvin Li",
        size: "60cm x 70cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Acrylic"
    },
    {
        name: "Relaxing sunset",
        filename: "8",
        price: "850",
        artist: "Lee Jia Rong",
        size: "57cm x 77cm with frame",
        profile: "Lee Jia Rong, 29 years old is a very special person who was diagnosed autistic at three years old. He is now an independent young man who can take care of himself. Jia Rong spend his time assisting his mother in updating sales journal and does the filling very efficiently. Reading is his love and books are his life companion. It was a back breaking achievement for his mother who guided him from young. She connected the written word with the physical environment to reinforce his cognitive development. Jia Rong has mastered writing Chinese characters using DVD and is now tri lingual. Along the way, jia Rong has developed a passion for airplanes and trains which he expresses ardently in his drawings and paintings. Jia Rong is now a calm young man who enjoys and takes great pride in his work. He has progressed from drawing with colour pencils to painting with acrylic on canvases. His drawings and paintings portray his artistic talents.",
        media: "Acrylic on canvas"
    },
    {
        name: "Blue And Red",
        filename: "9",
        price: "200",
        artist: "Darryon Leong",
        size: "28cm x 38cm",
        profile: "Darryon Leong Kai Ming, a 11 years old was an autism and ADHD kid. He is good sense of color and like to draw colorful art works. He often use watercolor as his medium in his drawings and paintings.",
        media: "Watercolour"
    },
    {
        name: "Kuching Water Front",
        filename: "10",
        artist: "Marcus",
        size: "37.5cm x 27.5cm",
        profile: "Marcus Au was diagnosed with Asperger’s Disorder when he was 3 years 3 months old. Despite the shortcomings, he is gifted with good drawing and coloring skills. He is good in sketching cartoon and recently he embarks in acrylic painting. He loves to use pen rendering, water color and acrylic medium in his painting.",
        media: "Pen sketching"
    },
    {
        name: "Cat and Hidden Eggs",
        filename: "11",
        price: "120",
        artist: "Nathan Chyr",
        size: "29.7cm x 42cm",
        profile: "Nathan is kind, friendly and has a good sense of humour. He plays piano, guitar and enjoys listening to music. He loves reading and often plays chess, Uno and other games in his spare time. He mostly use of watercolor as the medium in his painting. ",
        media: "Watercolour"
    },
    {
        name: "Dog",
        filename: "12",
        price: "300",
        artist: "Rachel Lee",
        size: "37cm x 27cm",
        profile: "Rachel is 15 years old  with autism. She loves craft and colouring.  She has a passion towards nature art paintings because she loves colourful flowers, trees and animals",
        media: "Crayon"
    },
    {
        name: "Parrot",
        filename: "13",
        price: "200",
        artist: "Steven Hwang",
        size: "33cm x 25cm",
        profile: "My name is Steven Hwang. I'm 15 years old. I'm autism.<br/>I like drawing and listening music.",
        media: ""
    },
    {
        name: "Black Panther",
        filename: "14",
        price: "500",
        artist: "Kelvin Li",
        size: "40cm x 40cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Acrylic"
    },
    {
        name: "Doughnut",
        filename: "15",
        price: "120",
        artist: "Nathan Chyr",
        size: "37.5cm x 27.8cm",
        profile: "Nathan is kind, friendly and has a good sense of humour. He plays piano, guitar and enjoys listening to music. He loves reading and often plays chess, Uno and other games in his spare time. He mostly use of watercolor as the medium in his painting. ",
        media: "Watercolour"
    },
    {
        name: "Save the Turtle",
        filename: "16",
        price: "500",
        artist: "Damian Clarke",
        size: "30cm x 19.5cm",
        profile: "Damian Clarke, 15 years old, a cheerful, obedient and fast learner teenager. Dinosaurs are his favourite. During free time, he enjoys playing Minecraft and Roblox and of cos watching Youtube.",
        media: "Acrylic"
    },
    {
        name: "Colourful Duck",
        filename: "17",
        price: "300",
        artist: "Darryon Leong",
        size: "28cm x 38cm",
        profile: "Darryon Leong Kai Ming, a 11 years old was an autism and ADHD kid. He is good sense of color and like to draw colorful art works. He often use watercolor as his medium in his drawings and paintings.",
        media: "Watercolour"
    },
    {
        name: "Cutie Cat",
        filename: "18",
        price: "Not for sale",
        artist: "Dave Hwang",
        size: "",
        profile: "My name is Dave Hwang. I'm 14 years old. I'm intellectual.<br/>I like drawing, photography and learning something new skills from online.",
        media: ""
    },
    {
        name: "Zebra",
        filename: "19",
        price: "850",
        artist: "Lee Jia Rong",
        size: "55cm x 74cm with frame",
        profile: "Lee Jia Rong, 29 years old is a very special person who was diagnosed autistic at three years old. He is now an independent young man who can take care of himself. Jia Rong spend his time assisting his mother in updating sales journal and does the filling very efficiently. Reading is his love and books are his life companion. It was a back breaking achievement for his mother who guided him from young. She connected the written word with the physical environment to reinforce his cognitive development. Jia Rong has mastered writing Chinese characters using DVD and is now tri lingual. Along the way, jia Rong has developed a passion for airplanes and trains which he expresses ardently in his drawings and paintings. Jia Rong is now a calm young man who enjoys and takes great pride in his work. He has progressed from drawing with colour pencils to painting with acrylic on canvases. His drawings and paintings portray his artistic talents.",
        media: "Acrylic on canvas"
    },
    {
        name: "The Gifted Art Exhibition",
        filename: "title",
    },
]

export const getArtDetails = (imgName) => {
    return pieces.find((p) => p.filename == imgName)
}

export const getArtName = (imgName) => {
    let art = pieces.find((p) => p.filename == imgName)
    return art.name
}
