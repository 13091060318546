
const pieces = [
    {
        // art1
        name: "China Local Dog", 
        filename: "133",
        price: "300",
        artist: "Kelvin Li",
        size: "37cm x 54cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Watercolour"
    },
    {
        // art2
        name: "Fox with Lantern",
        filename: "134",
        price: "300",
        artist: "Kelvin Li",
        size: "39cm x 54cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Watercolour"
    },
    {
        // art4
        name: "No Elephant Abuse",
        filename: "135",
        price: "300",
        artist: "Kelvin Li",
        size: "39cm x 54cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Watercolour"
    },
    {
        // art7
        name: "Shanghai Local Cats with Mask Acrylic",
        filename: "136",
        price: "600",
        artist: "Kelvin Li",
        size: "50cm x 50cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Acrylic"
    },
    {
        // art8
        name: "Siam Cat",
        filename: "137",
        price: "300",
        artist: "Kelvin Li",
        size: "39cm x 54cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Watercolour"
    },
    {
        // art9
        name: "Whale in the Sky",
        filename: "138",
        price: "800",
        artist: "Kelvin Li",
        size: "50cm x 50cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Acrylic"
    },
    {
        // art19
        name: "Year of Rabbit",
        filename: "139",
        price: "350",
        artist: "Kelvin Li",
        size: "39cm x 54cm",
        profile: "<p>Kelvin Li, an incredible autistic 17 years old young man born in Kuching and lived in Hangzhou China for eight years before moving back to Kuching at the age of nine. Kelvin had difficulties with his preschool and primary school years. He was not able to speak until he reached the age of five and half as well as not able to write his own name until the age of eight; given this difficulty, naturally he has problems communicating with others during this stage of his life. The school system in China was not able to accommodate to people with autism. Upon Kelvin returning to Kuching at the age of nine, he attended SJK Bintawa. With a dedicated persistent mother who wants to ensure that Kelvin receives the best education, Kelvin is currently attending Chung Hua Middle School No. 3 with extraordinary achievements. Kelvin started painting at the age of nine with animals as his main theme utilizing oil and acrylic paintings. Some of his paintings of killer whales and sea turtles were displayed at the Inclusion China Fundraising Project 2019. Some major events of Kelvin Li include and not limited to:</p><ul style='color: #000'><li style='color:#000'>November 2020, www.bykelvinli.com was established</li><li style='color:#000'>December 2021, the autism telemovie “The Acceptance” based on Kelvin Li’s real-life story was shot</li><li style='color:#000'>December 2021, Kelvin Li held his first solo art exhibition titled “The World of Animals”</li></ul>",
        media: "Watercolour"
    },
    {
        // art19
        name: "Field of Flowers",
        filename: "140",
        price: "250",
        artist: "Qiu Yan",
        size: "20cm x 20cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        // art19
        name: "Black, Black, Black",
        filename: "141",
        price: "280",
        artist: "Qiu Yan",
        size: "24cm x 17.5cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        // art19
        name: "Blessed Serendipity",
        filename: "142",
        price: "250",
        artist: "Qiu Yan",
        size: "20cm x 20cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        // art19
        name: "The Greener Side",
        filename: "143",
        price: "350",
        artist: "Qiu Yan",
        size: "23cm x 30cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        // art19
        name: "Wildchild",
        filename: "144",
        price: "450",
        artist: "Qiu Yan",
        size: "40cm x 30cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        // art19
        name: "When Spring Comes",
        filename: "145",
        price: "680",
        artist: "Qiu Yan",
        size: "40cm x 40cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        // art19
        name: "Poppy",
        filename: "146",
        price: "420",
        artist: "Qiu Yan",
        size: "30cm x 30cm",
        profile: "Qiu Yan started to draw sketches on papers since a very young age. Her family wasn’t aware, however, of what influenced her and how she developed the talent.<br/>Qiu Yan, was born with Down Syndrome, a genetic disorder caused by an extra chromosome. Along with her social media presence handled by her mother, Qiu Yan spreads her message of inclusion, and raises awareness and understanding about people with Down Syndrome.<br/>Her real-life story about juggling between her talent and life challenges was also featured on medias like Astro AEC, Astro AFC, and Sin Chew Daily. Through her beautiful talent and her remarkable spirit, Qiu Yan has inspired not only people with special needs, but also their close family members.<br/>Find out more about Qiu Yan at her <a href='https://linktr.ee/qiuyan'>Linktree</a>",
        media: "Acrylic on canvas"
    },
    {
        name: "The Gifted Art Exhibition",
        filename: "title",
    },
]

export const getArtDetails = (imgName) => {
    return pieces.find((p) => p.filename == imgName)
}

export const getArtName = (imgName) => {
    let art = pieces.find((p) => p.filename == imgName)
    return art.name
}
