export default function Place(object, props) {
    let {scale, position, rotate} = props
    object.scale.x = scale.x
    object.scale.y = scale.y
    object.scale.z = scale.z
    object.position.x = position.x
    object.position.y = position.y
    object.position.z = position.z
    object.rotateX(rotate.x)
    object.rotateY(rotate.y)
    object.rotateZ(rotate.z)
    return object
}